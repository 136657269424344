import React from 'react';
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { googleSignUp } from "shared/googleSignUp/actions";
import { microsoftSignUp } from "shared/microsoftSignUp/actions";
import { getUser } from 'data/person/actions';
import { Grid,Icon, Typography, Button } from '@material-ui/core';
import CustomSelect from "../../shared/components/CustomSelect";
import { object } from "prop-types";
import { Datepicker } from "react-formik-ui";
import DatePicker from "react-datepicker";
import { Formik, Form, ErrorMessage } from 'formik';
import CustomRadio from '../../shared/components/CustomRadio';
import CustomizedCheckBox from "../../shared/components/CustomizedCheckbox";
import { addSpaceAvailability, getSpaceAvailability, getCalendar } from 'data/spaces/actions';
import '../assets/styles/spacePlannerEdit.scss';
import Popover from '@material-ui/core/Popover';
import "../assets/styles/calendar.scss";
import DeletePdfComponent from './deletePdf';
import ViewPopup from '../../shared/components/viewPopup';
import EventCalendar from '../../shared/components/calendar_demo/index.js'
import * as Yup from 'yup';
import AddPdfComponent from './addPdf';
import EditPdfComponent from './editPdf';
import AddVideosComponent from './addVideos';
import ErrorPopup from '../../shared/components/errorPopup';
import ExtensionPopup from '../../shared/components/extensionPopup';
import DialogActions from '@material-ui/core/DialogActions';
import eventBroadcast from "../../data/eventBroadcast";
const profile_default=require('../../shared/components/assets/images/user-default.png');
let eventGroup = [];
let obj_b={};
let obj_a={};
let obj_r={};
let weekOff = [];

var weekday = new Array(7);
weekday[0] = "sunday";
weekday[1] = "monday";
weekday[2] = "tuesday";
weekday[3] = "wednesday";
weekday[4] = "thursday";
weekday[5] = "friday";
weekday[6] = "saturday";
let spaceId;
const monthNames = ["",
{short:"Jan",full:"January"}, 
{short:"Feb",full:"February"},
{short:"Mar",full:"March"}, 
{short:"Apr",full:"April"},
{short:"May",full:"May"}, 
{short:"Jun",full:"June"},
{short:"Jul",full:"July"}, 
{short:"Aug",full:"August"},
{short:"Sep", full:"September"},
{short:"Oct", full:"October"},
{short:"Nov", full:"November"},
{short:"Dec",full:"December"}
];
let handleDocumentDeleted;
let handleDocumentUpdated;
function tConvert(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];
  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
}
const styles = theme => ({
  icon: {
      // marginLeft: '8px',
      paddingTop: '1px',
      marginLeft:'2px',
      color: '#b0b0b0',
      fontSize: '15px',
      cursor:"pointer"
  },
  activeIcon: {
      // marginLeft: '8px',
      paddingTop: '1px',
      color: '#1397e1',
      fontSize: '15px'
  },
 
  typography: {
      width: '300px',
      height: '210px',
      fontSize: '14px',
      fontWeight: '500',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.43',
      letterSpacing: 'normal',
      padding: '19px',
      color: '#1397e1',
      fontFamily: 'Raleway'
  },
  popper: {
    paddingLeft: '15px',
    paddingTop: '237px'
},
root: {
    boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
    borderRadius: '6px'
},
paper: {
    width: '237px',
    height: '120px',
    fontSize: '14px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    padding: '19px',
    color: '#1397e1',
    boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
    fontFamily: 'Raleway',
    backgroundColor: '#ffffff'
},
currentDateText:{
  marginTop:'8px'
}
});

let makeToken=false;
let interval=null;
var getDaysArray = function(start, end) {
  for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  
  return arr;
};

class spacePlannerEdit extends React.Component {

  state={
    extensionPopup:false,
    extensionCalenderPopup:false,
    loaderClickSync:false,
    loaderMSClickSync:false,
    tier: 2,
    editspaceError:false,
    editspaceErrorMessage:'',
    // pdfData:[],
    pdfData:this.props.pdfData,
    anchorEl: null,
    dateAnchorEl: null,
    isToggleOpen: false,
    radioSelectedValue: '',
    checkBox:true,
    moment: moment(),   
    xCoordinate: '',
    yCoordinate: '',
    deleteID:'',
    hasAvailable: false,
    weekOff:[],
    isShown:0,
    editCalendarPopup:null,
    syncEditOption:null,
    xSyncCoordinate: '',
    ySyncCoordinate: '',
    startdate:new Date(),
    MIN_Date:(moment().subtract(1,'months')).toDate(),
  }
 

  UNSAFE_componentWillMount(){
    makeToken=false;
    this.setState({loaderClickSync:false,loaderMSClickSync:false})
    this.props.getUser().then((response) => {
      localStorage.setItem('profilePhoto',this.props.userData.profilePhoto ? this.props.userData.profilePhoto.path : profile_default);
    })
    const month = this.state.moment.month() + 1;
    const year = this.state.moment.year();
    this.props.getSpaceAvailability(this.props.match.params.spaceId, month, year)
    this.props.getCalendar(this.props.match.params.spaceId, month, year)
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    console.log('sdfs',nextProps.pdfData);
    console.log('qsqvideos',nextProps.videos);

    this.setState({pdfData:nextProps.pdfData})

    if((this.props.userData !== nextProps.userData && nextProps.userData.plan) && Object.keys(nextProps.userData).length !== 0){
      if(nextProps.userData.plan.tier !== null){
        this.setState({tier: nextProps.userData.plan.tier})
      }
      else if(nextProps.userData.plan.trail_tier !== null){
        this.setState({tier:nextProps.userData.plan.trail_tier})
      }

    }
    if(this.props.pdfData!==nextProps.pdfData){
      this.setState({pdfData:nextProps.pdfData})
    }
    if(this.props.spaceAvailabilityData !== nextProps.spaceAvailabilityData){

      if(nextProps.spaceAvailabilityData.length > 0){
        let availableSpace = nextProps.spaceAvailabilityData.find(x => x.status === 1);
        if(availableSpace !== undefined){
          this.setState({hasAvailable: true})
        }
        else{
          this.setState({hasAvailable: false})
        }
      }
      else{
        this.setState({hasAvailable: false})
      }
    }

    if(this.props.calendarData !== nextProps.calendarData){
        if(nextProps.calendarData.length > 0){
          this.setState({calendarData: nextProps.calendarData})
        }
        else{
          this.setState({calendarData: []})
        }
    }
       
    this.setState({weekOff:weekOff});
  }

  componentDidMount() {
    eventBroadcast.on("googleCalendarSync", (data) =>
      this.onClickGoto(this.props.values)
    );
    eventBroadcast.on("microsoftCalendarSync", (data) =>
      this.onSyncMircoSoft(this.props.values)
    );
  }

  componentWillUnmount() {
    eventBroadcast.remove("googleCalendarSync");
    eventBroadcast.remove("microsoftCalendarSync");
  }

  constructor(props) {
    super(props);
    spaceId = props.spaceId;
    handleDocumentDeleted = props.handleDocumentDeleted;
    handleDocumentUpdated = this.handleDocumentUpdated.bind(this)
    // this.handleClick = this.handleClick.bind(this);
    this.onClickCalendar = this.onClickCalendar.bind(this);
    this.handleNextMonth = this.handleNextMonth.bind(this);
    this.handlePreviousMonth = this.handlePreviousMonth.bind(this);
    this.handleToday = this.handleToday.bind(this);
  }

  handleSubmit = (values, actions) =>{
    const startDate = values.startDate
    const syncEnd = values.syncEnd
    const dateCount = parseInt(values.syncEnd.slice(0, 1));
    let endDate = ''
    let formData 
    if (syncEnd.includes('Week')){
      const numDays = dateCount * 7;
      endDate = new Date(startDate.getTime() + numDays * 24 * 60 * 60 * 1000);
      // endDate = startDate.setDate(startDate.getDate() + numDays);
    }
    else if(syncEnd.includes('Month')){
      // const numMonth = dateCount * 30
      // endDate = new Date(startDate.getTime() + numMonth * 24 * 60 * 60 * 1000);
      let year = startDate.getFullYear();
      let month = startDate.getMonth();
      let day = startDate.getDate();
      endDate = new Date(year, month + dateCount, day);
    }
    else if(syncEnd.includes('Year')){
      let year = startDate.getFullYear();
      let month = startDate.getMonth();
      let day = startDate.getDate();
      endDate = new Date(year + dateCount, month, day);
    }
    else{
      endDate = startDate
    }
    const endDateVal = moment(endDate).subtract(1, 'day').toDate()
    let start_m=startDate.getMonth()+1;
    let end_m=endDateVal.getMonth()+1;
    if(start_m.length===1){
      start_m='0'+start_m;
    }
    if(end_m.length===1){
      end_m='0'+end_m;
    }
    if(values.readyOption === 'blockOut'){
      makeToken = false
      const startingDate = moment(startDate).format("MM/DD/YYYY")
      const endingDate =moment(endDateVal).format("MM/DD/YYYY")
      let syear=startDate.getFullYear()+1;
      let eyear=endDateVal.getFullYear()+1;
      let edate=endDateVal.getDate();
      obj_b={};
      obj_b.description=" ";
      obj_b.title='';
      obj_b.eventClasses='blockOut';
      obj_b.start=startDate.getFullYear()+"-"+start_m+"-"+startDate.getDate();
      obj_b.end=endDateVal.getFullYear()+"-"+end_m+"-"+edate;
      obj_b.date=startDate.getFullYear()+"-"+start_m+"-"+startDate.getDate();

      obj_r={};
      obj_r.description=" ";
      obj_r.title='';
      obj_r.eventClasses='blockOut';
      obj_r.start=syear+"-"+start_m+"-"+startDate.getDate();
      obj_r.end=eyear+"-"+end_m+"-"+edate;
      obj_r.date=syear+"-"+start_m+"-"+startDate.getDate();

      if(values.agree && moment(values.startDate).format('DD-MMM-YYYY')!=moment().format('DD-MMM-YYYY')){
        formData= {
          space_id:this.props.spaceId,
          start_date: startingDate,
          end_date: endingDate,
          is_recurring:1,
          recurring_duration:1,
          status:0,
          current_date: values.currentDate
        }
      }
      else{
        formData= {
          space_id:this.props.spaceId,
          start_date: startingDate,
          end_date: endingDate,
          is_recurring:1,
          recurring_duration:0,
          status:0,
          current_date: values.currentDate
        }
      }
      
    }
    else{
      makeToken=true;
      obj_a.description=" ";
      obj_a.title='';
      obj_a.eventClasses='makeAvailableClass';
      obj_a.start=startDate.getFullYear()+"-"+start_m+"-"+startDate.getDate();
      obj_a.end=endDateVal.getFullYear()+"-"+end_m+"-"+endDateVal.getDate();
      obj_a.date=startDate.getFullYear()+"-"+start_m+"-"+startDate.getDate();

      const startingDate = moment(startDate).format("MM/DD/YYYY")
      const endingDate =moment(endDateVal).format("MM/DD/YYYY")
      formData= {
        space_id:this.props.spaceId,
        start_date: startingDate,
        end_date: endingDate,
        status:1,
        is_recurring:0,
        recurring_duration:0,
        current_date: values.currentDate
      }
    }
    const month = this.state.moment.month() + 1;
    const year = this.state.moment.year();
    this.props.addSpaceAvailability(formData).then((response)=>{
      this.props.getSpaceAvailability(this.props.match.params.spaceId, month, year)
      this.props.getCalendar(this.props.match.params.spaceId, month, year)
      actions.resetForm()
      this.setState({checkBox:true,radioSelectedValue:''})
    })
  }

  handleRadioClick = (event, setFieldValue,id) => {
    if(id === 'blockOut'){
      this.setState({checkBox:true})
    }
    else
    {
      setFieldValue('agree',false)
      this.setState({checkBox:false})

    }
    this.setState({ radioSelectedValue: event.target.value });
  }

  errorClose=()=>{
    this.setState({editspaceError:false,editspaceErrorMessage:""})

  }

  onClickGoto =(value)=>{

    if(value.description===null || value.description===''){
      this.setState({editspaceErrorMessage:'Complete venue set-up before syncing calendar or you may lose data.',editspaceError:true})
    }
    else{
      this.setState({loaderClickSync:true,editCalendarPopup:false});
      this.setState({editspaceErrorMessage:'',editspaceError:false});
      if(this.props.userData.refresh_token && this.props.userData.refresh_token !== '' && this.props.userData.identity_provider!='microsoft')
      {   
        if(this.props.redirectLink){
          this.props.redirectLink();
        }
        setTimeout(() => {
          var link = document.getElementById('submitSave');
          link.click(); 
        }, 500);

      }
      else
      {
        localStorage.setItem('spaceId',this.props.match.params.spaceId);
        this.props.googleSignUp().then((response) => {
          /*var link = document.getElementById('saveExit');
          link.click();  */  
          window.location.assign(response.googleUrlDetails.data.auth_url)
         })
      }
    }
  
  }

  onSyncMircoSoft =(value)=>{

    if(value.description===null || value.description===''){
      this.setState({editspaceErrorMessage:'Complete venue set-up before syncing calendar or you may lose data.',editspaceError:true})
    }
    else{
      this.setState({loaderMSClickSync:true,editCalendarPopup:false});
      this.setState({editspaceErrorMessage:'',editspaceError:false});
      let accessToken = undefined;
      if(this.props.userData.social_logins){
        accessToken = this.props.userData.social_logins.filter(element => element.identity_provider=='microsoft')[0];
      }   
      
      if(accessToken && accessToken.refresh_token && accessToken.refresh_token !== '')
      {         
        if(this.props.redirectMSLink){
          this.props.redirectMSLink();
        }
        setTimeout(() => {
          var link = document.getElementById('submitSave');
          link.click(); 
        }, 500);
       
      }
      else
      {
       
        localStorage.setItem('spaceId',this.props.match.params.spaceId);
        this.props.microsoftSignUp().then((response) => {
          window.location.assign(response.urlDetails.data.auth_url);
         });
      }
    }
  
  }

  tsLocation = ()=>{
    this.props.history.push(`/tslocations/${this.props.match.params.spaceId}`);
  }

  etSpaces = ()=>{
    this.props.history.push(`/etspaces/${this.props.match.params.spaceId}`);
  }
  showExtension = () =>{
    this.setState({extensionPopup:true})
  }
  showCalenderExtension = () =>{
    this.setState({extensionCalenderPopup:true})
  }
  handleHelpIconClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      isToggleOpen: true
    })
  }
  handleCloseHelpIcon = () => {
    this.setState({
      anchorEl: null,
      isToggleOpen: false
    });
  }

  //delete block out

  deleteBlockoutLocal=(id)=>{
    this.setState({deleteID:id})
  }

  //calendar data

  onClickCalendar=(e,{selected})=>{
  
  };
  getMouseCoordinate = (e) => {
    if (window.screen.width >= 500) {
    const xCoordinate = e.pageX - 269 + 'px'
    const yCoordinate = e.pageY - 241 + 'px'
    this.setState({
    xCoordinate: xCoordinate,
    yCoordinate: yCoordinate
    })
    } else {
    this.setState({
    xCoordinate: '',
    yCoordinate: ''
    })
    }
    this.setState({
    open: false,
    eventInfo: {}
    })
    };

    handlePreviousMonth() {
      this.setState({
          moment: this.state.moment.subtract(1, 'M'),
      });
      this.setState({
        startdate: this.state.moment.toDate(),
    });
      const month = this.state.moment.month() + 1;
      const year = this.state.moment.year();
      this.props.getCalendar(this.props.match.params.spaceId, month, year)
      this.props.getSpaceAvailability(this.props.match.params.spaceId, month, year)
    }
  
    handleNextMonth() {
      this.setState({
          moment: this.state.moment.add(1, 'M'),
      });
      this.setState({
        startdate: this.state.moment.toDate(),
    });
      const month = this.state.moment.month() + 1;
        const year = this.state.moment.year();
        this.props.getCalendar(this.props.match.params.spaceId, month, year)
        this.props.getSpaceAvailability(this.props.match.params.spaceId, month, year)
    }
  
    handleToday() {
      this.setState({
          moment: moment(),
     });
    }
    handlMonthSelect(date) {
      this.setState({startdate:date})
      const selectedMonth=(date.getMonth())
      const fullyear=date.getFullYear();
      const addMonth=selectedMonth-this.state.moment.month()
      this.setState({
        moment: this.state.moment.set({'year': fullyear}),
    }); 
     this.setState({
      moment: this.state.moment.add(addMonth, 'M'),
  }); 
        this.props.getCalendar(this.props.match.params.spaceId, this.state.moment.month()+1, fullyear)
        this.props.getSpaceAvailability(this.props.match.params.spaceId, this.state.moment.month()+1, fullyear)

    }

    handleFilter = (unique_arr, timeslotID, date) => {
      //let unique_arr=[];
      return unique_arr.filter( (ele, ind) => ele.timeslotID === timeslotID && ele.date === date )
    }
    closeExtPopup=()=>{
      this.setState({loaderClickSync:false,extensionPopup:false,loaderMSClickSync:false})
    }
    closeExtrnlPopup=()=>{
      this.setState({loaderClickSync:false,extensionPopup:false,extensionCalenderPopup:false,loaderMSClickSync:false})
    }

    handleDocumentUpdated = (index,updatedObject) => {
      let sarr = this.state.pdfData;
      sarr[index] =updatedObject; 
      this.setState({ pdfData: sarr });
   }
    componentDidMount() {
      if(this.props.match.params.spaceId){
        this.interval = setInterval(() => {
          const month = this.state.moment.month() + 1;
          const year  = this.state.moment.year();
          this.props.getCalendar(this.props.match.params.spaceId, month, year)
          this.props.getSpaceAvailability(this.props.match.params.spaceId, month, year)
        }, 60 * 1000);
      }
    }
    
    componentWillUnmount() {
      if(this.props.match.params.spaceId){
        clearInterval(this.interval);
      }
    }

  render() {
    eventGroup = []
    console.log(this.state.calendarData,eventGroup,"....calendar data")

    // const [ setAnchorEl] = React.useState(null);
    const { classes } = this.props;
    const anchorEl     = this.state.anchorEl;
    const dateAnchorEl = this.state.dateAnchorEl;
    const open = Boolean(anchorEl);
    const dateOpen = Boolean(dateAnchorEl);
    const handleClick = event => {
      this.setState({anchorEl : event.currentTarget});
    };

    const handleClose = () => {
      const month = this.state.moment.month() + 1;
      const year = this.state.moment.year();
      this.setState({anchorEl : null});
      this.props.getSpaceAvailability(this.props.match.params.spaceId, month, year)
      this.props.getCalendar(this.props.match.params.spaceId, month, year)
    }  

    const datePopuphandleClick = event => {
      this.setState({dateAnchorEl : event.currentTarget});
    };

    const datePopuphandleClose = () => {
      this.setState({dateAnchorEl : null});
    } 

    const id = open || dateOpen  ? 'simple-popper' : undefined;
    let initialValues={startDate:'', readyOption: 'blockOut', syncEnd:'', agree: false , makeavailable:false , readyOption1:false, currentDate: false};
    const validationSchema = Yup.object().shape({
      startDate: Yup.string("*Start date must be an string")
        .required('*Required'),
      readyOption: Yup.string("*Ready Option must be an string")
        .required('*Required'),
      syncEnd: Yup.string("*Ending must be an string")
        .required('*Required'),
      valuesChecked:Yup.boolean().oneOf([true],"*This must be checked."),

    });
    let syncEndArray=[{name:"1 Week"},
    {name:"2 Weeks"},
    {name:"3 Weeks"},
    {name:"1 Month"},
    {name:"2 Months"},
    {name:"3 Months"},
    {name:"6 Months"},
    {name:"1 Year"},
    {name:"2 Years"}];
  
    // const [ setAnchorEl] = React.useState(null);

    // const handleClick = event => {
    //   setAnchorEl(anchorEl ? null : event.currentTarget);
    // };
    // const id = open ? 'simple-popper' : undefined;
    // const handleClose = () => {
    //     setAnchorEl(null);
    //   }  

if(this.props.spaceId)
{
    eventGroup= []
    // const space = parseInt(this.props.spaceId,10); 
    if(this.props.spaceDetails){
      let response=this.props.spaceDetails;
      response.avilability = this.props.spaceAvailabilityData;
      makeToken=false;
      let time=[];
           let eventType=[];
           let mon=0;
           let tue=0;
           let wed=0;
           let thur=0;
           let fri=0;
           let sat=0;
           let sun=0;
           let days=[];
   
           let timeDaysMon=[];
           let timeDaysTue=[];
           let timeDaysWed=[];
           let timeDaysThur=[];
           let timeDaysFri=[];
           let timeDaysSat=[];
           let timeDaysSun=[];
   
           let objtimeMon={};
           let objtimeTue={};
           let objtimeWed={};
           let objtimeThur={};
           let objtimeFri={};
           let objtimeSat={};
           let objtimeSun={};
           weekOff   = [];
      let time_len=response.timeslots;
       if(time_len.length>0){
   
      for(let i=0;i<time_len.length;i++){
        let obj={};
        let price=0;
        obj.id=time_len[i].id;
        obj.time=tConvert(time_len[i].start_time.substring(0,5)) +"-"+tConvert(time_len[i].end_time.substring(0,5));
        obj.flexible=time_len[i].fixed_rental;
        obj.hours=time_len[i].min_hour;
        if(parseInt(time_len[i].monday)===1){
         timeDaysMon.push(time_len[i].id);
         weekOff.push(1)   
         mon=mon+1;
       }
       if(parseInt(time_len[i].tuesday)===1){
         timeDaysTue.push(time_len[i].id);
         weekOff.push(2)   
         tue=tue+1;
       }
       if(parseInt(time_len[i].wednesday)===1){
         timeDaysWed.push(time_len[i].id);
         weekOff.push(3)   
         wed=wed+1;
       }
       if(parseInt(time_len[i].thursday)===1){
         timeDaysThur.push(time_len[i].id);
         weekOff.push(4)      
         thur=thur+1;
       }
       if(parseInt(time_len[i].friday)===1){
         timeDaysFri.push(time_len[i].id);
         weekOff.push(5)  
         fri=fri+1;
       }
       if(parseInt(time_len[i].saturday)===1){
         timeDaysSat.push(time_len[i].id);
         weekOff.push(6)  
         sat=sat+1;
       }
       if(parseInt(time_len[i].sunday)===1){
         timeDaysSun.push(time_len[i].id);
         weekOff.push(0)  
         sun=sun+1;
       }
   
   
        if(time_len[i].event_types.length>0){
          for(let j=0;j<time_len[i].event_types.length;j++){
              eventType.push(time_len[i].event_types[j].name)
   
       
                if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===1){
                  price=price+time_len[i].event_types[j].pivot.base_rate;
                }
   
                if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===3){
                  price=price+time_len[i].event_types[j].pivot.fnb_rate + time_len[i].event_types[j].pivot.base_rate;
                }
   
                if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===4){
                  price=price+time_len[i].event_types[j].pivot.base_rate * time_len[i].event_types[j].pivot.min_guest;
                }
          }
          obj.avg=price / time_len[i].event_types.length;
        }
        else{
          obj.avg=0;
        }
   
        time.push(obj)
      }

      
   
      objtimeMon.count=mon;
      objtimeMon.id=timeDaysMon;
   
      objtimeTue.count=tue;
      objtimeTue.id=timeDaysTue;
   
      objtimeWed.count=wed;
      objtimeWed.id=timeDaysWed;
   
      objtimeThur.count=thur;
      objtimeThur.id=timeDaysThur;
   
      objtimeFri.count=fri;
      objtimeFri.id=timeDaysFri;
   
      objtimeSat.count=sat;
      objtimeSat.id=timeDaysSat;
   
      objtimeSun.count=sun;
      objtimeSun.id=timeDaysSun;
   
   
      days.push(objtimeMon);
      days.push(objtimeTue);
      days.push(objtimeWed);
      days.push(objtimeThur);
      days.push(objtimeFri);
      days.push(objtimeSat);
      days.push(objtimeSun);
   
   
      // eventGroup = eventType.filter( (ele, ind) => ind === eventType.findIndex( elem => elem == ele))
   
      // this.setState({timeSlot:time,spaceEventType:fd,days:days})
   
       }
       let token=0;
   
       if(response.avilability && response.avilability.length>0){
         for(let bi=0;bi<response.avilability.length;bi++){
          if(this.state.deleteID!==response.avilability[bi].id){
          let d=moment(response.avilability[bi].end_date).toDate()
          let month=d.getMonth()+1;
          let date=d.getDate();
          if(parseInt(month.length)===1){
            month="0"+month;
           }

           if(parseInt(response.avilability[bi].status)===0){
            // let obj={};
            // obj.description=" ";
            // obj.title='';
            // obj.eventClasses='blockOut';
            // obj.block_avail=response.avilability[bi].id;
            // obj.start=response.avilability[bi].start_date;
            // obj.end=d.getFullYear()+"-"+month+"-"+date;
            // obj.date=response.avilability[bi].start_date;
            // eventGroup.push(obj);

            let daylist = getDaysArray(new Date(moment(response.avilability[bi].start_date).format("MM/DD/YYYY")),new Date(moment(d.getFullYear()+"-"+month+"-"+date).format("MM/DD/YYYY")));         
            for(let i=0;i<daylist.length;i++){
              let maObj={};
              // makeToken=false;
              maObj.eventClasses='blockOut';
              maObj.description=" ";
              maObj.title='';
              maObj.start=moment(daylist[i]).format("YYYY-MM-DD");
              maObj.end=moment(daylist[i]).format("YYYY-MM-DD");
              maObj.date=moment(daylist[i]).format("YYYY-MM-DD");
              eventGroup.push(maObj);
      
            }
   
           }
           else if(parseInt(response.avilability[bi].status)===1){
            makeToken=true;
            // let maObj={};
            // makeToken=true;
            // maObj.eventClasses='makeAvailableClass';
            // maObj.description=" ";
            // maObj.block_avail=response.avilability[bi].id;
            // maObj.title='';
            // maObj.start=response.avilability[bi].start_date;
            // maObj.end=d.getFullYear()+"-"+month+"-"+date;
            // maObj.date=response.avilability[bi].start_date;
   
            // eventGroup.push(maObj);
    
           let daylist = getDaysArray(new Date(moment(response.avilability[bi].start_date).format("MM/DD/YYYY")),new Date(moment(d.getFullYear()+"-"+month+"-"+date).format("MM/DD/YYYY")));         
            for(let i=0;i<daylist.length;i++){
              let maObj={};             
              maObj.eventClasses='makeAvailableClass';
              maObj.description=" ";
              maObj.title='';
              maObj.start=moment(daylist[i]).format("YYYY-MM-DD");
              maObj.end=moment(daylist[i]).format("YYYY-MM-DD");
              maObj.date=moment(daylist[i]).format("YYYY-MM-DD");
              eventGroup.push(maObj);
            }
  
           }

           if(parseInt(response.avilability[bi].is_recurring)===1 && parseInt(response.avilability[bi].status)===0){             
             let s_d=moment(response.avilability[bi].start_date).toDate();
             let s_month=s_d.getMonth()+1;
             let s_date=s_d.getDate();
             let year=s_d.getFullYear()+1;
   
             let year2=s_d.getFullYear()+2;
   
             if(s_d.getMonth().length===1){
              s_month="0"+s_month;
             }
             if(s_d.getDay().length===1){
              s_date="0"+s_date
             }          
             let e_d = moment(response.avilability[bi].end_date).toDate()
             let e_month=e_d.getMonth()+1;
             let e_date=e_d.getDate();
   
             if(e_d.getMonth().length===1){
              e_month="0"+e_month;
             }
             if(s_d.getDay().length===1){
              s_date="0"+e_date
             }                
              let obj={};
              obj.description=" ";
              obj.title='';
              obj.eventClasses='blockOut';
              obj.start=year + "-"+s_month+"-"+s_date;
              obj.end=year + "-"+e_month+"-"+e_date;
              obj.date=year + "-"+s_month+"-"+s_date;
              obj.block_avail=response.avilability[bi].id;

              eventGroup.push(obj);
              obj={};
              obj.description=" ";
              obj.title='';
              obj.eventClasses='blockOut';
              obj.start=year2 + "-"+s_month+"-"+s_date;
              obj.end=year2 + "-"+e_month+"-"+e_date;
              obj.date=year2 + "-"+s_month+"-"+s_date;
              obj.block_avail=response.avilability[bi].id;

              eventGroup.push(obj);
   
           }
   
   
   
        
          }
          }
       }
      //  this.setState({daylist:block_list,makeAvailable:makeAvailabledates});
   
       let y=new Date().getFullYear();
       let m=new Date().getMonth() + 1;
       let d=new Date().getDate() - 1;
       
       if(parseInt(m)===1 ||  parseInt(m)===2 || parseInt(m)===3 || parseInt(m)===4 || parseInt(m)===5 || parseInt(m)===6 ||parseInt(m)===7 || parseInt(m)===8 || parseInt(m)===9){
        m="0"+m;
       }
  
   
      let objD={};
       objD.description=" ";
       objD.title='';
       objD.eventClasses='previous_dates';
       objD.start=y + 
       "-"+
       m+
       "-"+
       "01";
       objD.end=y + "-"+m+"-"+d;
       objD.date=y + 
       "-"+
       m+
       "-"+
       '01';
   
       eventGroup.push(objD);
      
      // response.event_timeslots && response.event_timeslots.map(function(eventData, idx) {  
    this.state.calendarData && this.state.calendarData && this.state.calendarData.map(function(eventData, idx) {    
      console.log(eventData,".....")
      if(eventData.parent_id!==null){  
    token=token+1;
    let obj={};
   
    const date = moment(eventData.date).toDate();
    const month = date.toLocaleString('default', { month: 'long' });
    var day = date.getDate();
    var year=date.getFullYear();
    var week_day= weekday[date.getDay()];
    obj.date=month.substring(0,3) + ' '+day+ ' ' + year;
    obj.name=eventData.event.name;
    obj.planner_id=eventData.event.event_planner && eventData.event.event_planner.id;
    obj.id=eventData.id;
    let day_count;
    let array=[];
    if(week_day==="monday"){
      day_count=0;
      array=days[0].id;
   
    }
    else if(week_day==="tuesday"){
      day_count=1;
      array=days[1].id;
   
    }
    else if(week_day==="wednesday"){
      day_count=2;
      array=days[2].id;
   
    }
    else if(week_day==="thursday"){
      day_count=3;
      array=days[3].id;
   
    }
    else if(week_day==='friday'){
      day_count=4;
      array=days[4].id;
   
    }
    else if(week_day==='saturday'){
      day_count=5;
      array=days[5].id;
   
    }
    else if(week_day==="sunday"){
      day_count=6;
      array=days[6].id;
   
    }

      obj.bookingType=eventData.hold_type;
    
   
      if(parseInt(eventData.hold_type)===1 
       && parseInt(days[day_count].count)===2)
       {
         
   
         if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
          obj.eventClasses='holdClassRight'; 
         }
         else{
           obj.eventClasses='holdClassLeft'; 
         }
     }
   
     else 
       if(parseInt(eventData.hold_type)===1 
       
         && parseInt(days[day_count].count)===1){
   
         obj.eventClasses='holdClassSingle';
     }
     
    else if(parseInt(eventData.hold_type)===1 && 
    parseInt(days[day_count].count)===4){
         if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
           obj.eventClasses='holdClass';
         }
         else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
           obj.eventClasses='holdClassOne4';
   
         }
         else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
           obj.eventClasses='holdClassTwo4';
   
         }
         else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
           obj.eventClasses='holdClassThree4';
   
         }
     }    
   
     else if(parseInt(eventData.hold_type)===1 
      && parseInt(days[day_count].count)===3){
         if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
           obj.eventClasses='holdClass3';
         }
         else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
           obj.eventClasses='holdClassOne3';
   
         }
         else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
           obj.eventClasses='holdClassTwo3';
   
         }
     }    
     
     else if((parseInt(eventData.hold_type)===4 && 
    parseInt(days[day_count].count)===2) ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===2))
     {
       
   
       if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
        obj.eventClasses='blockClassRight'; 
       }
       else{
         obj.eventClasses='blockClassLeft'; 
       }
   }
   else if(parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===2)
   {
     
 
     if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
      obj.eventClasses='bookClassRight'; 
     }
     else{
       obj.eventClasses='bookClassLeft'; 
     }
 }
 else if(parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===1){
  obj.eventClasses='bookClassSingle';
 }
   else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===1)||(parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===1)){
     obj.eventClasses='blockClassSingle';
    }
    else if(parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===4){
      if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
        obj.eventClasses='bookClass';
      }
      else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
        obj.eventClasses='bookClassOne4';
    
      }
      else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
        obj.eventClasses='bookClassTwo4';
    
      }
      else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
        obj.eventClasses='bookClassThree4';
    
      }
     }  
   else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===4) ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===4)){
     if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
       obj.eventClasses='blockClass';
     }
     else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
       obj.eventClasses='blockClassOne4';
   
     }
     else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
       obj.eventClasses='blockClassTwo4';
   
     }
     else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
       obj.eventClasses='blockClassThree4';
   
     }
    }  
   else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===3) 
    || (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===3)){
     if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
       obj.eventClasses='blockClass3';
     }
     else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
       obj.eventClasses='blockClassOne3';
   
     }
     else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
       obj.eventClasses='blockClassTwo3';
   
     }
   }
   else if(parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===3){
    if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
      obj.eventClasses='bookClass3';
    }
    else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
      obj.eventClasses='bookClassOne3';
  
    }
    else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
      obj.eventClasses='bookClassTwo3';
  
    }
  } 
   else if(parseInt(eventData.hold_type)===4){
     obj.eventClasses='blockClassSingle';
     }
     else
     {
      obj.eventClasses='blankClass';

     }
      obj.description=" ";
      obj.title='';
      obj.start=eventData.date;
      obj.end=eventData.date;
      obj.timeslotID=eventData.timeslot_id;
   
      if(eventData.timeslot.event_types){
      for(let i=0;i<eventData.timeslot.event_types.length;i++)
      {
      for(let j=0;j<eventData.timeslot.event_types[i].pivot.weekdays.length;j++){
      if(parseInt(date.getDay())===parseInt(eventData.timeslot.event_types[i].pivot.weekdays[j])){
      obj.startTime=eventData.timeslot.start_time;
      obj.endTime=eventData.timeslot.end_time;
      obj.eventType=eventData.timeslot.event_types[i].name;
      obj.price=eventData.timeslot.event_types[i].pivot.base_rate;
      obj.additionalPrice=eventData.timeslot.event_types[i].pivot.overage_rate;
      break;
      }
      }
      }
      }
  
      eventGroup.push(obj);

   
   }
   return eventGroup;
    })

    /*if(Object.keys(obj_b).length!==0){
      let daylist = getDaysArray(new Date(moment(obj_b.start).format("MM/DD/YYYY")),new Date(moment(obj_b.end).format("MM/DD/YYYY")));         
      for(let i=0;i<daylist.length;i++){
        let maObj={};
        makeToken=true;
        maObj.eventClasses='blockOut';
        maObj.description=" ";
        maObj.title='';
        maObj.start=moment(daylist[i]).format("YYYY-MM-DD");
        maObj.end=moment(daylist[i]).format("YYYY-MM-DD");
        maObj.date=moment(daylist[i]).format("YYYY-MM-DD");
        eventGroup.push(maObj);

      }
        }
    if(Object.keys(obj_r).length!==0){
      eventGroup.push(obj_r);
    }
    if(Object.keys(obj_a).length!==0){
      let daylist = getDaysArray(new Date(moment(obj_a.start).format("MM/DD/YYYY")),new Date(moment(obj_a.end).format("MM/DD/YYYY")));         
      for(let i=0;i<daylist.length;i++){
        let maObj={};
        makeToken=true;
        maObj.eventClasses='makeAvailableClass';
        maObj.description=" ";
        maObj.title='';
        maObj.start=moment(daylist[i]).format("YYYY-MM-DD");
        maObj.end=moment(daylist[i]).format("YYYY-MM-DD");
        maObj.date=moment(daylist[i]).format("YYYY-MM-DD");
        eventGroup.push(maObj);

      }

    }
    let event_arr=[];
    if(this.state.deleteID){
     for(let i=0;i<eventGroup.length;i++){
       if(parseInt(this.state.deleteID)===parseInt(eventGroup[i].block_avail)){

       }
       else
       {
        event_arr.push(eventGroup[i]);
       }
     }
     eventGroup=event_arr;
    }
*/
    let unique_arr=[];
    for(let i=0;i<eventGroup.length;i++){
    let event_check= this.handleFilter(unique_arr, eventGroup[i].timeslotID, eventGroup[i].date)//unique_arr.filter( (ele, ind) => ele.timeslotID === eventGroup[i].timeslotID && ele.date === eventGroup[i].date )
      if(event_check.length===0){
        unique_arr.push(eventGroup[i]);
      }
      else{

        if(parseInt(eventGroup[i].bookingType)===2 || parseInt(eventGroup[i].bookingType)===3 || parseInt(eventGroup[i].bookingType)===4 ){
          for( var j = 0; j < unique_arr.length; j++){
             if ( unique_arr[j].timeslotID === eventGroup[i].timeslotID && unique_arr[j].date === eventGroup[i].date) { unique_arr.splice(j, 1); }
            }
        // unique_arr = unique_arr.filter(data => data.date === eventGroup[i].date && data.timeslotID!==eventGroup[i].timeslotID);

        unique_arr.push(eventGroup[i]);
        }
      }
    }

    eventGroup=unique_arr;
  //  eventGroup = eventGroup.filter( (ele, ind) => ind === eventGroup.findIndex( elem => elem.timeslotID == ele.timeslotID && elem.date === ele.date ))
   
   
    
  }

   // })
  }
  
    return (
     
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          this.handleSubmit(values, actions)
        }}
      >             

      {({ isSubmitting, values, handleBlur, handleChange,setFieldValue }) => (  

<Form method="GET">
      {this.state.editspaceError===true ? 
      <ErrorPopup errorClose={this.errorClose} message={this.state.editspaceErrorMessage} openError={this.state.editspaceError} />
        : null}
        {this.state.extensionPopup===true ? 
      <ExtensionPopup close={this.closeExtPopup}/>
        : null}
           {this.state.extensionCalenderPopup===true ? 
      <ExtensionPopup closeExtrnlPopup={this.closeExtrnlPopup}/>
        : null}
<Grid container className="space-planner-edit">
<Grid xs={12} item>
  <Grid container>
    {/* {this.state.tier !== 1 ? */}
 
    {/* : null} */}
    <Grid item md={12} xs={12} sm={12} xl={12} lg={12} className="space-planner-edit-outer-grid">
    {/* {(this.state.tier !== 1 && this.state.tier !== null) ? */}
      <div className="are-there-restrictions">
      <div className="planner-title-block">
          <h3 className="black-heading">Edit your availability on when planners can place holds          
          <Grid className="help_icon">
              {/* <Grid onMouseOver={this.handleHelpIconClick} onMouseOut={this.handleCloseHelpIcon}>
                  <Icon className={this.state.isToggleOpen ? classes.activeIcon : classes.icon}>
                      help_outline
                  </Icon>
              </Grid>
              <Popper className={classes.popper} open={open} anchorEl={anchorEl} placement="right-end">
                  <Paper classes={{ root: classes.root }}>
                      <Typography className={classes.typography}>Tells us if you have any restrictions on your space.  If you have certain times of the year you would like your space available or blocked out.  If you only want your space available from current day for the next 3 months, tell us, or if you block out every summer for a festival or concert series, we want to know that too.</Typography>
                  </Paper>
              </Popper> */}
                        <Button aria-describedby={id} variant="contained" color="primary">
                        <Icon className={this.state.isToggleOpen ? classes.activeIcon : classes.icon} onClick={handleClick}>
                                help_outline
                        </Icon>                      
                        <Icon className={classes.icon} onClick={e=>{ window.open('https://firsthold.groovehq.com/help/edit-your-availability-on-when-planners-can-place-holds','_blank'); }} >
                            info_outline
                        </Icon>
                        </Button>
                      
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={this.state.anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                        >
                        <Typography className={classes.typography}>Tell us if you have any restrictions on your space.  If you have certain times of the year you would like your space available or blocked out.  If you only want your space available from current day for the next 3 months, tell us, or if you block out every summer for a festival or concert series, we want to know that too.</Typography>
                        </Popover>
            </Grid>
          </h3>
      </div>
      
      <div className="grid-section-vertical">
        <Grid item xs={12} sm={12} md={6} lg={6} className="box-left">
            <p className="other-info-label">Starting</p>
            <Datepicker
                minDate={moment().toDate()}
                disabled={values.currentDate}
               className='datepiker select_field' 
                //  style={{width:'40%'}} 
                placeholder="Start Date" 
                name='startDate' 
                dateFormat="MM/dd/yyyy"/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="box-right">
              <p>OR</p>
              <Button
                onClick={(e)=>{
                  setFieldValue('startDate', new Date())
                  setFieldValue('currentDate', true)
                }}
                variant="outline-danger"
                class="current-date"
                >
                Current Date  
              </Button>
              <Icon className={classes.icon} onClick={datePopuphandleClick}>
                  help_outline
                </Icon>
              <Popover
                  id={id}
                  open={dateOpen}
                  anchorEl={this.state.dateAnchorEl}
                  onClose={datePopuphandleClose}
                  anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                  }}
                  transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                  }}
              >
               
              <Typography className={classes.typography}>
              Click “current date” to create a continuous availability/limit window on holds.           
              <p className={classes.currentDateText}>Example : Prevent clients from holding dates less than a week in advance or holding dates more than three months in the future through this revolving feature.</p></Typography>
            </Popover>
          
        </Grid> 
        </div>

       {/* : null */}
    {/* } */}
    {/* {(this.state.tier !== 1 && this.state.tier !== null)? */}
      <div className="grid-section-vertical pt-2">
        <p className="other-info-label end-p">Ending</p>

          <div className="select-field small select-end">
            <CustomSelect
              display="inline-block"
              id="event_type"
              name="syncEnd"
              color="#62717a"
              width="80%"
              syncEndArray={syncEndArray}
              placeholder="Select"
              
            />
            <div className="error"><ErrorMessage name="syncEnd" /></div>

          </div>
      </div>
    {/* : null */}
    {/* } */}
      {/* {(this.state.tier !== 1 && this.state.tier !== null)? */}
      <div className="grid-section-vertical overflow-vis">
          <Grid item md={6} lg={5} className="d-none left-space">&nbsp;</Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} className="block-left">
                 {/* <CustomRadio
                  display="inline-block"
                  name="readyOption"
                  value={true}
                  id="readyOption"
                  onChange={this.handleRadioClick}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  class="radioButton"
                  radioSelectedValue={this.state.radioSelectedValue}
                /> */} 
                    {/* <CustomizedCheckBox 
                    name="blockOut"
                    // value={false}
                    setFieldValue={setFieldValue}
                    onChange={this.handleRadioClick}
                    handleChange={handleChange}
                    radioSelectedValue={this.state.radioSelectedValue}
                    display="inline-block"/>
                     <p> Block Out</p>
                      */}
                  <CustomRadio name="readyOption" label="Block Out" value={values.readyOption} id="blockOut" setFieldValue={setFieldValue} onRadioClick={this.handleRadioClick}/>
                
           </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} className="block-right">
              {/* <CustomRadio
                  name="readyOption"
                  value={true}
                  id="readyOption"
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  onChange={this.handleRadioClick}
                  class="radioButton"
                  radioSelectedValue={this.state.radioSelectedValue}
                />  */} 
                {/* <CustomizedCheckBox 
                    display="inline-block"
                    // value={false}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    radioSelectedValue={this.state.radioSelectedValue}
                    onChange={this.handleRadioClick}
                    name="makeavailable"/>
                    <p>Make Available</p> */}
                    {!this.state.hasAvailable &&
                      <CustomRadio name="readyOption" label="Make Available" value={values.readyOption} id="makeavailable" setFieldValue={setFieldValue} onRadioClick={this.handleRadioClick}/>
                    }
            </Grid>                                 
            <div className="error"><ErrorMessage name="agree" /></div>
      </div>
    {/* : null */}
    {/* } */}
   
    {this.state.checkBox===true && moment(values.startDate).format('DD-MMM-YYYY')!=moment().format('DD-MMM-YYYY')?
    <div className="make-annual">
        <CustomizedCheckBox 
        name='agree' 
        //  value={props.formikProps.values.agree} 
        label="Make Annual" />
    </div>:null}
    <Grid item xs={12} sm={12} className="restriction-button">
              <div className="white-button-div">
                  <Button variant="outline-danger" class="red-button" type="submit">
                      Save
                  </Button>
              </div>
          </Grid>  
          </div>
    {/* {(this.state.tier !== 1 && this.state.tier !== null)?  */}
      <div className="grid-section-vertical">
        <p className="other-info-label"></p>
        {/* <p className="small-text grey">From the current day.</p> */}
      </div>
      {/* <VenueCalendar name="venueProfileCalendar" eventGroup={eventGroup} /> */}
      <Grid className="calendar" item xs={12}
                        onMouseDown = { (e) => { this.getMouseCoordinate(e) }} >
                        
                        <div className="button-calendar">
                        {new Date() > this.state.moment?
                     <Button style={{color:'grey'}}>&lt;</Button>
                              :
                     <Button onMouseDown={this.handlePreviousMonth}>&lt;</Button>

                          } <div>                         
                <DatePicker
                minDate={this.state.MIN_Date}
                selected={this.state.startdate}
                onChange={(date) => this.handlMonthSelect(date)}
                className='today'  
                name='start' 
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                showFullMonthYearPicker               
                /></div>

                            {/* <Button onClick={this.handleNextMonth}>&gt;</Button> */}
                            <Button onMouseDown={this.handleNextMonth}>&gt;</Button>
                        </div>

                        <EventCalendar
                          makeToken={makeToken}
                          month={this.state.moment.month()}
                          year={this.state.moment.year()}
                          events={eventGroup} 
                          weekOff = {this.state.weekOff}
                           />
                        
          </Grid>
          { this.state.editCalendarPopup && 
            <Grid container justify= "center" className="sync_calendar_popup event_calendar" >
                  <Grid item className = "event_info_popup" style = {{left: (this.state.xSyncCoordinate !== '' && this.state.xSyncCoordinate),top: (this.state.ySyncCoordinate !== '' && this.state.ySyncCoordinate)}}>
                    <Grid className="event_date" >
                      { this.state.syncEditOption==1? 'Edit Calendar':'Sync Calendar' }
                      <DialogActions className="dialogActions calendar-close">                 
                      <span className="material-icons close closeButton" onClick={()=>this.setState({editCalendarPopup:false})} >close</span>
                      </DialogActions>
                    </Grid>
                    { this.state.syncEditOption==1 &&  
                    <Grid container justify= "center" className="cal-box">
                      <div className="calendar-icon" onMouseEnter={() => this.setState({isShown:1})}  onMouseLeave={() => this.setState({isShown:0})}>                      
                        <img src={require('../assets/images/google_calendar.svg')} alt="Google"/>
                          <a rel="noopener noreferrer" className="link" href='https://outlook.live.com/calendar/0/view/month' target="_blank" title="Calendar">{this.state.isShown==1 && (<p>Google</p>)}</a>
                      </div> 
                      <div className="calendar-icon microsoft" onMouseEnter={() => this.setState({isShown:2})}  onMouseLeave={() => this.setState({isShown:0})}>                       
                      <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft"/>                     
                      <a rel="noopener noreferrer" className="link" href='https://outlook.live.com/calendar/0/view/month' target="_blank" title="Calendar">{this.state.isShown==2 && (<p>Microsoft</p>)} </a>
                      </div>                    
                    </Grid>
                    }
                    
                    <Grid container justify= "center" className="cal-box">
                    <div style={{display:'none'}} className="calendar-icon show-ext-popup" onClick={()=>this.onClickGoto(this.props.values)} onMouseEnter={() => this.setState({isShown:1})}  onMouseLeave={() => this.setState({isShown:0})}>
                        <img src={require('../assets/images/google_calendar.svg')} alt="Google"/>
                        {this.state.isShown==1 && (<p>Google</p>)}
                      </div>
                      <div style={{display:'none'}} className="calendar-icon microsoft show-ext-popup" onClick={()=>this.onSyncMircoSoft(this.props.values)} onMouseEnter={() => this.setState({isShown:2})}  onMouseLeave={() => this.setState({isShown:0})}>
                        <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft"/>
                        {this.state.isShown==2 && (<p>Microsoft</p>)}
                      </div>
                    { localStorage.getItem("extensionCalenderPopup")? 
                      <div className="calendar-icon hide-ext-popup" onClick={()=>this.onClickGoto(this.props.values)} onMouseEnter={() => this.setState({isShown:1})}  onMouseLeave={() => this.setState({isShown:0})}>
                        <img src={require('../assets/images/google_calendar.svg')} alt="Google"/>
                        {this.state.isShown==1 && (<p>Google</p>)}
                      </div> :
                      <div className="calendar-icon hide-ext-popup" onClick={()=>this.showCalenderExtension()} onMouseEnter={() => this.setState({isShown:1})}  onMouseLeave={() => this.setState({isShown:0})}>
                      <img src={require('../assets/images/google_calendar.svg')} alt="Google"/>
                      {this.state.isShown==1 && (<p>Google</p>)}
                    </div>
                      }
                         { localStorage.getItem("extensionPopup")? 
                      <div className="calendar-icon microsoft hide-ext-popup" onClick={()=>this.onSyncMircoSoft(this.props.values)} onMouseEnter={() => this.setState({isShown:2})}  onMouseLeave={() => this.setState({isShown:0})}>
                        <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft"/>
                        {this.state.isShown==2 && (<p>Microsoft</p>)}
                      </div>:
                      <div className="calendar-icon microsoft hide-ext-popup" onClick={()=>this.showExtension()} onMouseEnter={() => this.setState({isShown:2})}  onMouseLeave={() => this.setState({isShown:0})}>
                      <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft"/>
                      {this.state.isShown==2 && (<p>Microsoft</p>)}
                    </div>
                      }
                      <div className="calendar-icon microsoft" onClick={()=>this.tsLocation(this.props.values)} onMouseEnter={() => this.setState({isShown:3})}  onMouseLeave={() => this.setState({isShown:0})}>
                        <img src={require('../assets/images/tripleseat.svg')} alt="Tripleseat"/>
                        {this.state.isShown==3 && (<p>Tripleseat</p>)}
                      </div>
                      <div className="calendar-icon microsoft" onClick={()=>this.etSpaces(this.props.values)} onMouseEnter={() => this.setState({isShown:4})}  onMouseLeave={() => this.setState({isShown:0})}>
                        <img  src={require('../assets/images/EventTemple.jpg')} alt="Event Temple"/>
                        {this.state.isShown==4 && (<p className="EventTemple">Event Temple</p>)}
                      </div>
                    </Grid>
                  </Grid>
          </Grid>
        }
        
      <div className="grid-section-vertical sub-section subsec-btn">
        <div className="red-button-div">
        { (this.state.loaderClickSync===true || this.state.loaderMSClickSync===true)
        ?
        <img style={{height:'34px',marginTop:'0px',paddingTop:'0px'}} src={require('../../shared/components/assets/images/loading.gif')} alt="Venue" /> 
        :
        <div>
          {/*localStorage.getItem("extensionPopup")?*/}
           <div>
            <Button id="syncCalendarHidden" variant="outline-danger" onMouseDown = { (e) => {  if (window.screen.width >= 500) { this.setState({xSyncCoordinate: e.pageX - 150 + 'px',ySyncCoordinate: e.pageY - 100 + 'px'}) }}} class="red-button float-left" onClick={()=>this.setState({editCalendarPopup:true,syncEditOption:2})} >
            Sync Calendar
            </Button>    
          </div>
          {/*:
          <div>
            <Button id="syncCalendarHidden"  style={{display:'none'}} variant="outline-danger" onMouseDown = { (e) => {  if (window.screen.width >= 500) { this.setState({xSyncCoordinate: e.pageX - 150 + 'px',ySyncCoordinate: e.pageY - 100 + 'px'}) }}} class="red-button float-left" onClick={()=>this.setState({editCalendarPopup:true,syncEditOption:2})} >
            Sync Calendar
            </Button>  
            <Button style={{display:'none'}} id="syncCalendarHidden" variant="outline-danger" class="red-button float-left"  onClick={()=>this.onClickGoto(this.props.values)} >
            Sync Google Calendar
            </Button>
            <Button style={{display:'none'}} id="syncMicrosoftCalendarHidden" variant="outline-danger" class="red-button float-left"  onClick={()=>this.onSyncMircoSoft(this.props.values)} >
            Sync Microsoft Calendar
            </Button> 
            <Button id="syncCalendar" variant="outline-danger" class="red-button float-left" onClick={()=>this.showExtension()} >
            Sync Calendar
            </Button> 
          </div>
          */}
        </div>
        }
        </div>
        <div className="white-button-div">
            <Button variant="outline-danger" id="editCalendar" onMouseDown = { (e) => {  if (window.screen.width >= 500) { this.setState({xSyncCoordinate: e.pageX - 130 + 'px',ySyncCoordinate: e.pageY - 50 + 'px'}) }}} class="white-button float-right" onClick={()=>this.showExtension()}>
              Edit Calendar   
            </Button>
            <Button id="hideEditcalendar" style={{display:'none'}} onClick={()=>this.setState({editCalendarPopup:true,syncEditOption:1})}  onMouseDown = { (e) => {  if (window.screen.width >= 500) { this.setState({xSyncCoordinate: e.pageX - 130 + 'px',ySyncCoordinate: e.pageY - 50 + 'px'}) }}} variant="outline-danger" class="white-button float-right" >
              Edit Calendar                
            </Button> 
        </div>
        <div className="white-button-div view">
            <ViewPopup deleteBlockoutLocal={this.deleteBlockoutLocal} spaceAvailabilityData={this.props.spaceAvailabilityData} requesting={this.props.requesting} spaceId={this.props.spaceId} month={this.state.moment.month() + 1} year={this.state.moment.year()} />
        </div>
      
        {/* <div className="white-button-div view">
            <VenuePublishPopup />
        </div> */}
      </div>


      {
      /*<div className="grid-section-vertical sub-section subsec-btn">
      <div className="red-button-div">
      {
        this.state.loaderMSClickSync===true
        ?
        <img style={{height:'34px',marginTop:'0px',paddingTop:'0px'}} src={require('../../shared/components/assets/images/loading.gif')} alt="Venue" /> 

        :
        localStorage.getItem("extensionPopup")?
        <Button id="syncMicrosoftCalendarHidden" variant="outline-danger" class="red-button float-left"  onClick={()=>this.onSyncMircoSoft(this.props.values)} >
              Sync microsoft Calendar
        </Button>
        :
        <Button id="syncCalendar" variant="outline-danger" class="red-button float-left" onClick={()=>this.showExtension()} >
        Sync microsoft Calendar
        </Button>
      }      
      </div>
      <div className="white-button-div" style={{width:'56%'}}>
            <Button  variant="outline-danger" class="white-button" type="submit">
                <a rel="noopener noreferrer" className="link" href='https://outlook.live.com/calendar/0' target="_blank" title="Calendar">
                    Edit Calendar     
                </a>
            </Button>
        </div>
        
    </div>
    */}

      <div className="grid-section-vertical sub-section bordered speces">
        <ul className="plan_description">
        <AddPdfComponent 
        copyFalse={this.props.copyFalse} 
        copy={this.props.copy} 
        venueDetails={this.props.venueDetails} 
        name="SPACE" 
        pdfData={this.state.pdfData} 
        type="SPACE" id={this.props.spaceId} 
        handleDocumentUploaded={this.props.handleDocumentUploaded}/>

     {this.state.pdfData?
      this.state.pdfData.length>0 ? this.state.pdfData.map(function(data, idx) {
        return(
        <li>
        <div className="blue_icon_div">
          <span className="material-icons picture_as_pdf">picture_as_pdf</span>
        </div>
        <div className="text">
        <a rel="noopener noreferrer"
        target="_Blank"
       href={'/pdfReader?url='+data.path}>
      {data.name}         
      </a>
        </div>

        <div className="plan-descp-icons">

            <a rel="noopener noreferrer"
            target="_Blank"
            href={'/pdfReader?url='+data.path}>
              <Icon>remove_red_eye</Icon>
            </a>
            <a href={data.path} download>
                  <Icon>get_app</Icon>
              </a>
              <EditPdfComponent type="SPACE" data={data}  handleDocumentUpdated={handleDocumentUpdated} index={idx}  />
              <DeletePdfComponent type="SPACE" id={spaceId} documentId={data.id} handleDocumentDeleted={handleDocumentDeleted} />
      </div>


      </li>
        )
      })
    : <div style={{float:'left',margin:'10px 0 0 4px'}}>No document Found</div>
 
 :null
}

        </ul>
      </div>
      <div className="grid-section-vertical sub-section bordered speces">
        <AddVideosComponent type="SPACE" id={spaceId} videos={this.props.videos} handleVideoUpdate={this.props.handleVideoUpdate}  handleVideoAdd={this.props.handleVideoAdd}  handleVideoDeleted={this.props.handleVideoDeleted}         venueDetails={this.props.venueDetails} 
         name="SPACE" copyFalse={this.props.copyFalse} copy={this.props.copy}/>
      </div>

    </Grid>
  </Grid>
</Grid>
</Grid>


</Form>
       )}
      </Formik>

   
      
);
  }
}
spacePlannerEdit.propTypes = {
  classes: object.isRequired,

};
const mapStateToProps = state => {
  return {
    userData: state.data.auth.userData,
    spaceAvailabilityData: state.data.spaces.spaceAvailabilityData,
    requesting: state.data.spaces.requesting,
    calendarData: state.data.spaces.calendarData
  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        addSpaceAvailability,
        googleSignUp,
        microsoftSignUp,
        getUser,
        getSpaceAvailability,
        getCalendar
      },
      dispatch
    )
  )
);
export default enhance(spacePlannerEdit);

