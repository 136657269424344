import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "./assets/style/myEvents.scss";
import CustomisedRadio from "./CustomisedRadio.js";
import CustomTimeSelect from "./CustomTimeSelect.js";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Autocomplete from "react-autocomplete";
import CustomSelect from "./CustomSelect";
import { Datepicker } from "react-formik-ui";
import { eventList, eventType } from "../placeHolds/actions";
import { createEvent } from "../createEvent/actions";
import { func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import { seatingCategory } from "shared/search/actions";
import { saveEvent,updateEventData,deleteEvent } from "shared/holdEvent/actions";
import { updateEvent } from "shared/updateEvent/actions";
import SelectBudget from 'shared/components/SelectBudget'; 
import { city } from "shared/search/actions";
import ModalBoxEvent from "../../shared/components/ModalBoxEvent";


let seatingType = [];
let eventData = [];
const RegEx = /^[0-9]{0,8}[-][0-9]{0,8}$/;


const styles = theme => ({
  root: {
    margin: 0,
    overflow: "visible"
  },
  paperWidthSm: {
    margin: "20px",
    overflow: "visible"
  },

  paper: {
    margin: "20px",
    overflow: "visible"
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      fontWeight: "500",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.46px",
      color: "black"
    }
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      borderRadius: "6px",
      border: "solid 1px black"
    }
  }
});
const EventSchema = Yup.object().shape({
  eventName: Yup.string()
    .required("*Required"),
  city: Yup.string()
    .required("*Required"),
  event_type: Yup.string()
    .required("*Required"),
  budget: Yup.string()  
    .required("*Required"),
  place_hold_seating: Yup.string()
    .matches(RegEx, "Please enter a range (i.e. 100-150)")
    .required("*Required"),
  startDateRange: Yup.string()
    .required("*Required"),
  // endDateRange: Yup.string()
  //   .required("*Required"),
  startTime: Yup.string()
    .required("*Required"),
  endTime: Yup.string()
    .required("*Required"),
  setUp: Yup.string()
    .required("*Required"),
  takeDown: Yup.string() 
    .required("*Required"),
  seating: Yup.string()
    .required("*Required")
});

withStyles(theme => ({
  root: {}
}))(MuiDialogContent);

const setUpArray = [
  {
    id: "1 hr",
    name: "1 hr"
  },
  {
    id: "1 hr",
    name: "2 hr"
  },
  {
    id: "1 hr",
    name: "3 hr"
  },
  {
    id: "1 hr",
    name: "4 hr"
  },
  {
    id: "1 hr",
    name: "5 hr"
  },
  {
    id: "1 hr",
    name: "6 hr"
  },
  {
    id: "1 hr",
    name: "7 hr"
  },
  {
    id: "1 hr",
    name: "8 hr"
  },
  {
    id: "1 hr",
    name: "9 hr"
  },
  {
    id: "1 hr",
    name: "10 hr"
  },
  {
    id: "1 hr",
    name: "11 hr"
  },
  {
    id: "1 hr",
    name: "12 hr"
  },
  {
    id: "1 hr",
    name: "24 hr"
  },
  {
    id: "1 hr",
    name: "48 hr"
  },
  {
    id: "1 hr",
    name: "72 hr"
  }
];

class MyEventsData extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
    findtheVenue:false,
    holdPopupState:false,
    date: "",
    id:"",
    open: false,
    showForm: false,
    eventsPresent: false,
    eventsSelected: false,
    eventsChecked: false,
    buttonDisabled: true,
    buttonSaveDisabled: true,
    getResponse: false,
    radioSelectedValue: "",
    seatingType: [],
    timeError: "",
    // setUpError: "",
    // takeDownError: "",
    hold_id:"",
    eventData:[],
    hold_rank:'',
    event_id:'',
    budget:'',
    city:'',
    end_time:'',
    event_type:'',
    name:'',
    seating:'',
    seating_type:'',
    setup:'',
    teardown:'',
    start_time:'',
    update:false,
    hold_value:'',
    cityList:[]
  };
  componentWillMount() {
    this.setState({ holdPopupState:false,getResponse: false,eventData:[] });
    eventData=[];
    this.props.eventList().then((response) => {
        if (this.props.eventData.data.length > 0) {
          this.setState({ eventsPresent: true });
          response.eventDetails.data.map(function(data, idx) {
            // let holdRankArray = [];
            // let holdTypeArray = [];
            let obj = {};
            let min=data.min_seating?data.min_seating:0;
            let max=data.max_seating?data.max_seating:0;

            
            obj.id = data.id?data.id:null;
            obj.name = data.name?data.name:null;
            obj.city=data.city?data.city:null;
            obj.seating=min+'-'+max;
            obj.event_type=data.event_type ? data.event_type.id : null;
            obj.start_time=data.start_time?data.start_time:null;
            obj.end_time=data.end_time?data.end_time:null;
            obj.budget=data.budget?data.budget:null;
            obj.seating_type=data.arrangement ? data.arrangement.id:null;
            obj.setup=data.setup_time ? data.setup_time : null;
            obj.teardown=data.teardown_time ? data.teardown_time : null;
            obj.start_date_follow=data.start_date;
            obj.end_date_follow=data.end_date;

            if(data.start_date !== null && data.end_date!==null){
            if (data.start_date === data.end_date) {
              obj.date = moment(data.start_date).format("MM/DD/YYYY");
            } else {
              obj.date = moment(data.start_date).format("MM/DD/YYYY") +" to "+ moment(data.end_date).format("MM/DD/YYYY");
            }
          }
          else
          {
            obj.date=null;
          }

            //hold values
  
                if (typeof(data.hold_count) === 'number') {
                  if(parseInt(data.hold_count)===0){
                    obj.holds = "No Hold";
                  }
                  else if(parseInt(data.hold_count)===1){
                    obj.holds = data.hold_count + " Hold";

                  }
                  else{
                    obj.holds = data.hold_count + " Holds";

                  }
                } else {
                  obj.holds = "Booked";
                }
              obj.numeric_hold=data.hold_count;

            

            eventData.push(obj);
            return eventData;
          });
          this.setState({eventData:eventData, getResponse: "calling", showForm: false });

        }
        else
        {
          this.setState({ getResponse: 'noData'})

        }
      })
      .catch(error => this.setState({ getResponse: false, errorValue: true }));
        this.props.eventType().then(() => {
        this.setState({ showForm: false });
      })
      .catch(error => this.setState({ getResponse: false, errorValue: true }));

    this.props.seatingCategory().then(response => {
        seatingType = [];
        response.data.map(function(data, idx) {
          let obj = {};
          obj.value = data.id;
          obj.name = data.name;
          seatingType.push(obj);
          return seatingType;
        });
        this.setState({ seatingType: seatingType });
      })
      .catch(error => this.setState({ errorValue: true }));


      this.props.city().then(response=>{
      }).catch(error=>{});

      this.props.history.listen((location, action) => {
        if(location.pathname == '/myEvents'){
          this.handleCancelClick();
        }
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({holdPopupState:false});

    let cityList = []
    if(this.props.cityInfo !== nextProps.cityInfo && Object.keys(nextProps.cityInfo).length > 0){
      nextProps.cityInfo.data.map((city,idx)=>{
        cityList.push({ key: city.city, id: idx})
        return cityList;
      })
      this.setState({cityList: cityList})
    }
  }
  holdPopup=()=>{
    this.setState({holdPopupState:true})

  }
  handleClose = () => {

    this.setState({
      open: true,
      holdPopupState:false,
      showForm: false,
      eventsSelected: false,
      eventsPresent: true,
      eventsChecked: false,
      buttonSaveDisabled:true,
      radioSelectedValue:'',
      budget:'',
      city:'',
      date:'',
      end_time:'',
      event_type:'',
      name:'',
      seating:'',
      seating_type:'',
      setup:'',
      teardown:'',
      start_time:'',
      event_id:'',
      start_date:'',
      end_date:'',
      hold_value:''



    });
  };

  handleCreateEventClickNoData = () => {
      this.setState({ showForm: true, eventsChecked: false,getResponse:'calling'})
  };

  handleCreateEventClick = (data) => {
    this.setState({holdPopupState:false,hold_value:'',budget:'',city:'',date:'',end_time:'',event_type:'',name:'',seating:'',seating_type:'',setup:'',teardown:'',start_time:'',event_id:'',update:'',start_date:'',end_date:''});

    if(data.name){
    function tConvert (time) {
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    
      if (time.length > 1) { 
        time = time.slice (1);
        time.pop();
      }
      return time.join (''); 
    }
    let start_date;
    let end_date;
    let date;
    if(data.date!==null && data.date.includes('to')===true){
      date=data.date.split('to');
      start_date=date[0];
      end_date=date[1];
    }
    else
    {
      start_date=data.date;
      end_date=data.date;

    }

    let setup=data.setup !== null && data.setup.split('.');
    let teardown=data.teardown !== null && data.teardown.split('.');
    this.setState({ showForm: true, eventsChecked: false,

      budget:data.budget,
      city:data.city,
      date:'',
      end_time:tConvert(data.end_time),
      event_type:data.event_type,
      name:data.name,
      seating:data.seating,
      seating_type:data.seating_type,
      setup:setup[0]+" hr",
      teardown:teardown[0]+" hr",
      start_time:tConvert(data.start_time),
      event_id:data.id,
      update:true,
      start_date:start_date,
      end_date:end_date,
      hold_value:data.holds

 });
 }
 else
 {

  this.setState({ showForm: true, eventsChecked: false})
 }
  };

  openExtension = ()=>{
    window.open('https://chrome.google.com/webstore/detail/first-hold/gbgllbpnnhldcnpkbfmiiikkbggjknke','_blank');
  }

  handleCancelClick = () => {
  

    this.setState(prevState => ({ holdPopupState:false,showForm: false, open: true }));
  };
  findVenue=()=>{
    this.setState({findtheVenue:true})

    var link = document.getElementById('findavenue');
    link.click();
  }
  handleSubmit = (values, { setSubmitting }) => {
    this.setState({
      name: values.eventName,
      holdPopupState:false,

      // takeDownError: "",
      // setUpError: "",
      timeError: ""
    });

    let seating = values.place_hold_seating.split("-");
    let setup_time = values.setUp.split(" ");
    let takeDown = values.takeDown.split(" ");
    let json = {};
    json.event_type_id = values.event_type;
    json.name = values.eventName;
    json.city = values.city;
    json.start_date = moment(values.startDateRange).format("YYYY-MM-DD");
    if(values.endDateRange){
      json.end_date = moment(values.endDateRange).format("YYYY-MM-DD");

    }
    else{
      json.end_date = moment(values.startDateRange).format("YYYY-MM-DD");

    }
    json.start_time = values.startTime + ":00";
    json.end_time = values.endTime + ":00";
    json.setup_time = setup_time[0];
    json.teardown_time = takeDown[0];
    json.min_seating = seating[0];
    json.max_seating = seating[1];
    json.budget = values.budget;
    json.arrangement_id = values.seating;

    
    var start_time = new Date(
      moment().format("LL") + " " + values.startTime + ":00"
    );
    var end_time = new Date(
      moment().format("LL") + " " + values.endTime + ":00"
    );
    // if (start_time.getTime() > end_time.getTime()) {
    //   this.setState({ timeError: "Invalid Time" });
    //   this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    // }
    let matched_min = new Date(moment().format("LL") + " 00:00:00");

    if (
      start_time.getTime() - setup_time[0] * 3600000 <
      matched_min.getTime()
    ) {
      // this.setState({ setUpError: "Invalid Time" });
      this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    }
    let matched_max = new Date(moment().format("LL") + " 24:00:00");
    if (end_time.getTime() + takeDown[0] * 3600000 > matched_max.getTime()) {
      // this.setState({ takeDownError: "Invalid Time" });
      this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    }

    if (this.state.timeError === "") {      

      if(this.state.update===true){
        //update
        let arr=this.state.eventData;
        let update_arr=[];

        this.props.updateEvent(this.state.event_id,json).then((response)=>{
          this.setState(prevState => ({ showForm: false, eventsPresent: true }));
          if(this.state.findtheVenue===true){
            window.location.href ='./browseVenue?guest_count='+seating[0]+"-"+seating[1]+'&address='+values.city;
          }
          for(let i=0;i<arr.length;i++){
            let obj={};
            if(parseInt(this.state.event_id)===parseInt(arr[i].id)){
              obj.budget=values.budget;
              obj.city=values.city;
              obj.start_time = values.startTime + ":00";
              obj.end_time = values.endTime + ":00";
              obj.event_type=values.event_type;
              obj.id=this.state.event_id;
              obj.name=values.eventName;
              obj.seating=seating[0]+"-"+seating[1];
              obj.seating_type=values.seating;
              obj.holds=arr[i].holds;
              obj.setup=setup_time[0];
              obj.teardown=takeDown[0];
              obj.start_date_follow=values.startDateRange;
              if(values.endDateRange){
                obj.end_date_follow=values.endDateRange;
              }
              else
              {
                obj.end_date_follow=values.startDateRange;

              }
              if (!values.endDateRange || values.startDateRange.toString() === values.endDateRange.toString()) {
                obj.date = moment(values.startDateRange).format("MM/DD/YYYY");
              } else {
                obj.date = moment(values.startDateRange).format("MM/DD/YYYY") +" to "+ moment(values.endDateRange).format("MM/DD/YYYY");
              }
            }
            else{
              obj=arr[i];
            }

            update_arr.push(obj)
          }
          this.setState({eventData:update_arr,update:false})


     
        }).catch(error=>{});
      }
      else{
        this.props.createEvent(json).then((response) => {
          this.setState(prevState => ({ showForm: false, eventsPresent: true }));
          let arr=this.state.eventData;
          let obj={};
          if(this.state.findtheVenue===true){
            window.location.href ='./browseVenue?guest_count='+seating[0]+"-"+seating[1]+'&address='+values.city;
          }
          obj.budget=values.budget;
          obj.city=values.city;
          obj.start_time = values.startTime + ":00";
          obj.end_time = values.endTime + ":00";
          obj.event_type=values.event_type;
          obj.id=response.eventDetails.data.id;
          obj.name=values.eventName;
          obj.seating=seating[0]+"-"+seating[1];
          obj.seating_type=values.seating;
          obj.setup=setup_time[0];
          obj.teardown=takeDown[0];
          obj.holds='No Hold';
          obj.start_date_follow=values.startDateRange;
          if(values.endDateRange){
            obj.end_date_follow=values.endDateRange;
          }
          else
          {          
            obj.end_date_follow=values.startDateRange;
          }
          if (!values.endDateRange || values.startDateRange.toString() === values.endDateRange.toString()) {
            obj.date = moment(values.startDateRange).format("MM/DD/YYYY");
          } else {
            obj.date = moment(values.startDateRange).format("MM/DD/YYYY") +" to "+ moment(values.endDateRange).format("MM/DD/YYYY");
          }
          arr.push(obj);

          this.setState({
            eventData:arr,
            // takeDownError: "",
            // setUpError: "",
            timeError: ""
          });
          

        }).catch(error=>{});
      }


    }
  };
  handleCheckboxClick = event => {
    this.setState({ eventsChecked: true,holdPopupState:false });
    this.setState({ buttonSaveDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });

    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName("option");

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName("input")[0].checked) {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text blue");
      } else {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text grey");
      }
    }
  };
  handleSaveToEventClick = () => {
    this.setState({holdPopupState:false})

    let params={
    
      space_id:this.props.match.params.spaceId,
      event_id:this.state.radioSelectedValue
    };
    let updatedEventArray = this.state.eventData;
    this.props.saveEvent(params, 'yes').then((response) => {

      this.props.calendarData(response);
      //put below code above
      for(let i=0;i<updatedEventArray.length;i++){
        if(parseInt(this.state.radioSelectedValue)===parseInt(updatedEventArray[i].id)){
          let value=updatedEventArray[i].numeric_hold;
          if(value===undefined){
            value=1;
            value=value + " Hold";

          }
          else{
            value=value+1;
            value=value + " Holds";

          }
          
          updatedEventArray[i].holds=value;
          this.setState({eventData:updatedEventArray})

        }
      }
       this.setState({hold_id:response.holeEventDetails.data[0].id,hold_rank:response.holeEventDetails.data[0].hold_rank})
      this.setState(prevState => ({
        eventsPresent: false,
        eventsSelected: true
      }));
     })

  
  };

  handleRadioClick = event => {
    this.setState({holdPopupState:false})
    this.setState({ buttonDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });

    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName("option");

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName("input")[0].checked) {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text blue");
      } else {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text grey");
      }
    }
  };
  handleOkClick = ()=>{
    this.setState({holdPopupState:false})

    let params={hold_reason:this.state.radioSelectedValue};
    this.props.updateEventData(this.state.hold_id,params).then(() => {
      this.setState({open:false});
      this.props.releaseHold(this.state.hold_id,this.state.hold_rank)

   })

  };
  onSelectStart=(values,setFieldValue,eTime)=>{
if(eTime===''){
  let starttime=values.target.value;
  let time=starttime.split(':');

  if(parseInt(time[1])+30===60){

    starttime=parseInt(time[0])+
    1 +
    ":"+
    "00";

    let a=(parseInt(time[0])+1).toString();
  if(a.length===1)
  {
    starttime="0"+starttime;

  }

  }
  else{
    let time_for=parseInt(time[1])+30;
    if(time_for[1]===0){
      time_for=time_for.replace(0,'');
    }
    else{
      time_for=parseInt(time[1])+30;
    }

    starttime=time[0]+":"+time_for;

  }
  setFieldValue('endTime',starttime);

}

  }
  deleteEvent=(id,newElement)=>{
    this.setState({holdPopupState:false})

this.props.deleteEvent(id).then(() => {
 let arr = this.state.eventData.filter(function(obj) {
    return obj.id !== id;
});
if(newElement)
{
  this.setState({eventData:arr,name:'',date:''});

}
else
{
  this.setState({eventData:arr});

}

})
  }
  onSelectedDate=(setFieldValue)=>{
    setFieldValue('endDateRange','')
  }
  render() {
    // const { classes } = this.props;
    // const eventsPresent = this.state.eventsPresent;
    var currentDate = moment().toDate();
    let initialValues={
      eventName:this.state.name?this.state.name:"",
      city: this.state.city?this.state.city:"",
      event_type: this.state.event_type?this.state.event_type:"",
      budget: this.state.budget?this.state.budget:"",
      place_hold_seating: this.state.seating?this.state.seating:"",
      startDateRange: this.state.start_date?this.state.start_date:"",
      endDateRange: this.state.end_date?this.state.end_date:"",
      startTime: this.state.start_time?this.state.start_time:"",
      endTime: this.state.end_time?this.state.end_time:"",
      setUp: this.state.setup?this.state.setup:"",
      takeDown: this.state.teardown?this.state.teardown:"",
      seating: this.state.seating_type?this.state.seating_type:"",
      check_select: ""
    };
    
    return (

  
      <Grid className="my_event_container">
                    <ModalBoxEvent check={this.state.holdPopupState}/>

 {this.state.getResponse==='calling'?
    
                     localStorage.getItem("fhtoken") &&
                    parseInt(localStorage.getItem("type")) === 0 ? (
                      <Grid item xs={12} className="main_place_hold_section">

                        {this.state.showForm ? (
                  <Formik
                  initialValues={initialValues}
                  validationSchema={EventSchema}
                  onSubmit={this.handleSubmit}
                >             
  
                  {({ isSubmitting, values, handleBlur, handleChange,setFieldValue }) => (       
                  <Form>            
                        <div className="form">
                         <div className="header">
                           {
                            (this.state.update === true)&& 
                             <div className="header-text">Event Details</div>
                           }
                           {
                            (this.state.update !== true) && 
                              <div className="header-text">Add New Event</div>
                            }
                           <div className="header-button-div-form button-left">
                            <Button
                              
                               variant="outline-danger"
                               className="red-button-small"
                               onClick={()=>this.findVenue(values)}
                             >
                              Find a Venue
                               </Button>
                             <Button
                               variant="outline-danger"
                               className="white-button-small"
                               onClick={this.handleCancelClick}
                             >
                               Cancel
                             </Button>
                             <Button
                             id="findavenue"
                             name="findavenue"
                               variant="outline-danger"
                               className="red-button-small"
                               type="submit"
                             >
                               {this.state.update===true?'Update':'Save'}
                             </Button>
                           </div>
                         </div>
                         {/* <div className="form-content"> */}
                           <div className="field-div big">
                             <div className="input-label">Event Name</div>
                             <div className="input-field-section">
                    
                               <Field
                                 className="input-field dark-black"
                                 type="text"
                                 name="eventName"
                               />
                               <div className="error">
                                 <ErrorMessage name="eventName" />
                               </div>
                             </div>
                           </div>
  
                           <div className="field-div small">
                             <div className="input-label sm-pd-city">City</div>
                             <div className="input-field-section select-down-icon">
                             <Autocomplete
                                fields={{ groupBy: 'value', value: 'menu' }}
                                value={ values.city }
                                inputProps={{id: 'city',placeholder:'City'}}
                                wrapperStyle={{ position: 'relative' }}
                                items={ this.state.cityList }
                                getItemValue={ item => item.key }
                                shouldItemRender={(item, value) => item.key.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                onChange={(e) => {setFieldValue('city', e.target.value)}}
                                onSelect={(val,item) => {setFieldValue('city',val)}}
                                renderMenu={ children => (
                                <div className = "suggestionDiv">
                                    { children }
                                </div>
                                )}
                                renderItem={ (item, isHighlighted) => (
                                <div
                                className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                key={ item.id } >
                                { item.key }
                                </div>
                                )}
                            />
                              <div className="error"><ErrorMessage name='city' /></div>
                               {/* <input
  name="city"
  type="text"
  placeholder="San Francisco"
  className="input-field light-black"
  /> */}
                               {/* <Field
                                 className="input-field dark-black"
                                 type="text"
                                 name="city"
                               /> */}

                              {/* <CustomSelect
                                 id="city"
                                 name="city"
                                 color="#62717a"
                                 width="100%"
                                 data={this.props.cityInfo.data}
                                 placeholder="Select"
                                 disable={this.state.hold_value?true:false}
                               />
                               <div
                                 style={{ display: "inline-block" }}
                                 className="error"
                               >
                                 <ErrorMessage name="city" /> */}
                               </div>
                             </div>
            
  
                           <div className="field-div small float-right">
                             <div className="input-label sm-pd-city"># of People</div>
                             <div className="input-field-section">
                               {/* <CustomSelect
  id="place_hold_seating"
  name="place_hold_seating"
  color="#62717a"
  width="100%"
  selected="100-300"
  placeholder="Select"
  /> */}
                               <Field
                                 className="input-field dark-black"
                                 type="text"
                                 name="place_hold_seating"
                                 placeholder="Ex-100-300"
                                 disabled={this.state.hold_value !== 'No Hold' && this.state.hold_value!==''?true:false}
                               />
  
                               <div
                                 style={{ display: "inline-block" }}
                                 className="error"
                               >
                                 <ErrorMessage name="place_hold_seating" />
                               </div>
                             </div>
                           </div>
  
  
                           <div className="field-div small">
                             <div className="input-label sm-pd-city">Event Type</div>
                             <div className="input-field-section select-down-icon">
                               <CustomSelect
                                 id="event_type"
                                 name="event_type"
                                 color="#62717a"
                                 width="100%"
                                 value={values.event_type}
                                 data={this.props.eventTypeData.data}
                                 placeholder="Select"
                                 disable={this.state.hold_value !== 'No Hold' && this.state.hold_value!==''?true:false}
                               />
                               <div
                                 style={{ display: "inline-block" }}
                                 className="error"
                               >
                                 <ErrorMessage name="event_type" />
                               </div>
                             </div>
                           </div>
  
                           <div className="field-div small float-right">
                             <div className="input-label sm-pd-city">Venue Rental Budget</div>
                             <div className="input-field-section budget">
                                <div className="input-field-section select-down-icon">
                                    <SelectBudget />
                                    <div
                                      style={{ display: "inline-block" }}
                                      className="error"
                                    >
                                    <ErrorMessage name="budget" />
                                  </div>
                                </div>
                             </div>
                           </div>

                           <div className="field-div small disp-none-big">
                             <div className="input-label sm-pd-seating">Seating Type</div>
                             <div className="input-field-section select-down-icon">
                               <CustomSelect
                                 id="seating"
                                 name="seating"
                                 value={values.seating}
                                 color="#62717a"
                                 width="100%"
                                 placeholder="Select"
                                 seatingData={seatingType}
                                 disable={this.state.hold_value !== 'No Hold' && this.state.hold_value!==''?true:false}

                               />
                               <div
                                 style={{ display: "inline-block" }}
                                 className="error"
                                   >
                                 <ErrorMessage name="seating" />
                               </div>
                             </div>
                            </div>

                           <div className="field-div small">
                             <div className="field-div smaller">
                               <div className="input-label sm-pd-start">
                                 Start Date Range
                               </div>
                               <div className="input-field-section">
                               {/* {values.endDateRange?           : 
                               <Datepicker
                                  onKeyDown={e => e.preventDefault()}
                                   minDate={currentDate}
                                   className="datepiker"
                                   name="startDateRange"
                                   dateFormat="MM/dd/yyyy"
                                   placeholder="Start Date"
                                   disabled={this.state.hold_value?true:false}

                                 />
                              } */}
                                <Datepicker
                                onKeyDown={e => e.preventDefault()}
                                // maxDate={moment(
                                //   values.endDateRange
                                // ).toDate()}
                                 minDate={currentDate}
                                 onSelect={()=>this.onSelectedDate(setFieldValue)}
                                 className="datepiker"
                                 name="startDateRange"
                                 dateFormat="MM/dd/yyyy"
                                 placeholder="Start Date"
                                 disabled={this.state.hold_value !== "No Hold" && this.state.hold_value!==''?true:false}

                               />
                    
                                 
                               </div>
                             </div>
  
                             <div className="field-div spacer disp-none-sm">to</div>
                             <div className="field-div smaller float-right">
                               <div className="input-label sm-pd-end">
                                 End Date Range (Optional)
                               </div>
                               <div className="input-field-section">
                                {/* {values.startDateRange?
                                  <Datepicker
                                  onKeyDown={e => e.preventDefault()}

                                   className="datepiker"
                                   minDate={moment(
                                     values.startDateRange
                                   ).toDate()}
                                   name="endDateRange"
                                   dateFormat="MM/dd/yyyy"
                                   placeholder="End Date"
                                   maxDate={moment(values.startDateRange)
                                     .add(15, "days")
                                     .toDate()}
                                     disabled={this.state.hold_value?true:false}

                                 />
                                : } */}
                                <Datepicker
                                  onKeyDown={e => e.preventDefault()}
                                   className="datepiker"
                                   minDate={values.startDateRange}
                                   name="endDateRange"
                                   dateFormat="MM/dd/yyyy"
                                   placeholder="End Date"
                                   maxDate={moment(values.startDateRange)
                                     .add(15, "days")
                                     .toDate()}
                                     disabled={this.state.hold_value !== "No Hold" && this.state.hold_value!==''?true:false}

                                 />
                                
                                 
                               </div>
                             </div>
                           </div>
  
                           <div className="field-div small float-right">
                             <div className="field-div smaller">
                               <div className="input-label sm-pd-start">Start Time</div>
                               <div className="input-field-section">
                                 <CustomTimeSelect
                                   placeholder="Start Time"
                                   styleClass="browseVenue"
                                   name="startTime"
                                   value={values.startTime}
                                   handleChange={e => {
                                    handleChange(e); 
                                    this.onSelectStart(e,setFieldValue,values.endTime)
                                  }}
                     
                                   handleBlur={handleBlur}
                                   
                                   disable={this.state.hold_value !== "No Hold" && this.state.hold_value!==''?true:false}

                                 />
                               </div>
                             </div>
  
                             <div className="field-div spacer disp-none-sm">to</div>
  
                             <div className="field-div smaller float-right">
                               <div className="input-label sm-pd-end">End Time</div>
                               <div className="input-field-section">
                                 <CustomTimeSelect
                                   placeholder="End Time"
                                   styleClass="browseVenue"
                                   name="endTime"
                                   value={values.endTime}
                                   handleChange={handleChange}
                                   handleBlur={handleBlur}
                                   disable={this.state.hold_value !== 'No Hold' && this.state.hold_value!==''?true:false}

                                 />
                                 <div className="error">
                                   {this.state.timeError}
                                 </div>
                               </div>
                             </div>
                            </div>

                                  <div className="field-div small float-right">
                             <div className="field-div smaller">
                               <div className="input-label sm-pd-req-up">Requested Set-up Time</div>
                               <div className="input-field-section select-down-icon">
                                 <CustomSelect
                                   className="select-field lighter-black"
                                   id="setUp"
                                   setUpArray={setUpArray}
                                   name="setUp"
                                   value={values.setUp}
                                   placeholder="Select"
                                   color="#62717a"
                                   width="100%"
                                   marginTop="10px"
                                   disable={this.state.hold_value !== 'No Hold' && this.state.hold_value!==''?true:false}

                                 />
                                 {/* {this.state.setUpError ? (
                                   <div
                                     className="error"
                                     style={{ display: "inline-block" }}
                                   >
                                     {this.state.setUpError}
                                   </div>
                                 ) : null} */}
  
                                 <div
                                   style={{ display: "inline-block" }}
                                   className="error"
                                 >
                                   <ErrorMessage name="setUp" />
                                 </div>
                               </div>
                             </div>
  
                             <div className="field-div smaller float-right">
                               <div className="input-label sm-pd-req-down">Requested Tear Down Time</div>
                               <div className="input-field-section select-down-icon">
                                 <CustomSelect
                                   className="select-field lighter-black"
                                   id="takeDown"
                                   setUpArray={setUpArray}
                                   name="takeDown"
                                   value={values.takeDown}
                                   placeholder="Select"
                                   color="#62717a"
                                   width="100%"
                                   marginTop="10px"
                                   disable={this.state.hold_value !== 'No Hold' && this.state.hold_value!==''?true:false}

                                 />
                                 {/* {this.state.takeDownError ? (
                                   <div
                                     className="error"
                                     style={{ display: "inline-block" }}
                                   >
                                     {this.state.takeDownError}
                                   </div>
                                 ) : null} */}
  
                                 <div
                                   style={{ display: "inline-block" }}
                                   className="error"
                                 >
                                   <ErrorMessage name="takeDown" />
                                 </div>
                               </div>
                             </div>
                           </div>
                           
                           <div className="field-div small disp-none-small">
                              <div className="input-label sm-pd-seating">Seating Type</div>
                                  <div className="input-field-section select-down-icon">
                                        <CustomSelect
                                          id="seating"
                                          name="seating"
                                          color="#62717a"
                                          width="100%"
                                          placeholder="Select"
                                          value={values.seating}
                                          seatingData={seatingType}
                                          disable={this.state.hold_value !== 'No Hold' && this.state.hold_value!==''?true:false}

                                        />
                                        <div
                                          style={{ display: "inline-block" }}
                                          className="error"
                                        >
                                          <ErrorMessage name="seating" />
                                        </div>
                                  </div>
                                </div>
                            </div>
                    </Form>
                         )}
                 </Formik>
           
                  ) : this.state.eventsPresent ? (
                          <div className="events">
                            <div className="header">                                                  
                              <div className="header-button-div">
                               <Button variant="outline-danger" class="red-button-small" onClick={this.openExtension}>Add to Chrome</Button>
                                <Button
                                  variant="outline-danger"
                                  class="white-button-big"
                                  onClick={this.handleCreateEventClick}
                                >
                                  Create New Event
                                </Button>
                              </div>
                            </div>
                            <div className="events-content" style={{overflow:"auto",margin:'0px'}} id="eventsContent">
                           

                              {this.state.eventData.map(data => {
                                return (
                                  <div className="eventDiv" style={{margin:'20px auto 20px'}}>                               
                                    <div className="detailsDiv">
                                      <div className="large-text" onClick={()=>this.handleCreateEventClick(data)}>
                                        {data.name}
                                        <p className="blue-text">{data.date}</p>
                                      </div>

                                      <div className="actionDiv">
                                        {/* <i className="hand-icon"></i> */}
                                        {data.holds!==undefined && data.holds!=='0 Holds' && data.holds!=='0 Hold' && data.holds!=='No Hold'? (
                                          <p onClick={()=>this.holdPopup()} className="grey-text">
                                            {data.holds}
                                          </p>
                                        ) : (
                                          <p className="grey-text">No Holds</p>
                                        )}
                                  {/* <div className="red_icon_div" style={data.holds ?null:{color:'red'}} > */}
                                     <span style={data.holds!=='0 Holds' && data.holds!=='0 Hold' && data.holds!=='No Hold' ?null:{color:'red'}} onClick={data.holds!=='0 Holds' && data.holds!=='0 Hold' && data.holds!=='No Hold'? null:()=>this.deleteEvent(data.id)} className="material-icons delete">delete</span>
                                 {/* </div> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            </div>
                        
                          </div>
                        ) : this.state.eventsSelected ? (
                          <div className="eventsSelected">
                            <img
                              className="image"
                              src={require("./assets/images/logo.png")}
                              alt="FIRST-HOLD"
                            />

                            <p className="large-text">Hold Placed</p>

                            <p className="description">
                              The venue manager will be notified and will be in
                              touch shortly
                            </p>

                            <p className="description">
                              You’ll get notifications through your
                              extension
                            </p>

                            <p className="description blue-text">
                              One last thing!
                            </p>

                            <p className="description">
                              How ready are you to book?
                            </p>

                            <p className="grey-text">Select one</p>

                            <div className="optionsDiv">
                              <div className="option">
                                <p className="option-text">
                                  Currently <br />
                                  Just Looking
                                </p>

                                <CustomisedRadio
                                  name="readyOption"
                                  value=" Currently Just Looking"
                                  onChange={this.handleRadioClick}
                                  class="radioButton"
                                  radioSelectedValue={
                                    this.state.radioSelectedValue
                                  }
                                />
                              </div>
                              <div className="option">
                                <p className="option-text">
                                  This is one of my top options!
                                </p>

                                <CustomisedRadio
                                  name="readyOption"
                                  value=" This is one of my top options!"
                                  onChange={this.handleRadioClick}
                                  class="radioButton"
                                  radioSelectedValue={
                                    this.state.radioSelectedValue
                                  }
                                />
                              </div>
                              <div className="option">
                                <p className="option-text">
                                  I’m probably going to book this space.
                                </p>

                                <CustomisedRadio
                                  name="readyOption"
                                  value=" I’m probably going to book this space."
                                  onChange={this.handleRadioClick}
                                  class="radioButton"
                                  radioSelectedValue={
                                    this.state.radioSelectedValue
                                  }
                                />
                              </div>
                            </div>

                            <div className="footerButtonDiv">
                              <Button
                                variant="contained"
                                disabled={this.state.buttonDisabled}
                                size={"large"}
                                fullWidth={true}
                                onClick={this.handleOkClick}
                                className="grey-button"
                              >
                                ok
                              </Button>
                            </div>
                          </div>
                        ) : this.state.eventData.length === 0 && this.state.getResponse!==false ? (
                          <div className="default">
                            <div className="header">
                              
                              <div className="header-button-div">
                                <Button variant="outline-danger" class="red-button-small" onClick={this.openExtension}>Add to Chrome</Button>
                                <Button
                                  variant="outline-danger"
                                  class="white-button-big"
                                  onClick={this.handleCreateEventClick}
                                >
                                  Create New Event
                                </Button>
                              </div>
                            </div>
                            <div className="uploader-content">
                              <div className="uploader">
                                <p className="red-text-content">
                                  Create Your First Event
                                </p>
                                <p className="black-text-content">
                                  Takes about a minute.
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : "Loading..."}
                      </Grid>
                    ) : (
                      <Grid item xs={12} className="main_place_hold_section">
                        <div className="form">
                          <div className="header">
                            <div className="header-text">Login</div>
                          </div>
                          <div className="form-content">
                          Please Log-in as a Planner
                          </div>
                        </div>
                      </Grid>
                    )
                    : 
                    this.state.eventData.length === 0 && this.state.getResponse === "noData" ? 








<div className="default">
<div className="header">
   
  <div className="header-button-div">
    <Button variant="outline-danger" class="red-button-small" onClick={this.openExtension}>Add to Chrome</Button>
    <Button
      variant="outline-danger"
      class="white-button-big"
      onClick={this.handleCreateEventClickNoData}
    >
      Create New Event
    </Button>
  </div>
</div>
<div className="uploader-content">
  <div className="uploader" onClick={this.handleCreateEventClickNoData} style={{cursor:'pointer'}}>
    <p className="red-text-content">
      Create Your First Event
    </p>
    <p className="black-text-content">
      Takes about a minute.
    </p>
  </div>
</div>
</div>

                      :
                      <div class="loading">Loading...
                      </div>
                    }
                
            </Grid>
       
    );
  }
}

MyEventsData.propTypes = {
  classes: PropTypes.object.isRequired,
  eventList: func.isRequired,
  eventType: func.isRequired,
  seatingCategory: func.isRequired,
  createEvent: func.isRequired,
  saveEvent:func.isRequired,
  updateEvent:func.isRequired,
  updateEventData:func.isRequired,
  deleteEvent:func.isRequired,
  city:func.isRequired
};

const mapStateToProps = state => {
  return {
    eventData: state.data.eventList.eventDetails,
    eventTypeData: state.data.eventList.eventTypeDetails,
    seatingCategoryData: state.data.browseVenueData.arrangementData,
    holdEventData:state.data.saveEvent,
    cityInfo:state.data.browseVenueData.cityData
  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        eventList,
        eventType,
        seatingCategory,
        createEvent,
        saveEvent,
        updateEvent,
        updateEventData,
        deleteEvent,
        city
      },
      dispatch
    )
  )
);
export default enhance(MyEventsData);
