import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "./../assets/style/seperateplacehold.scss";
import DialogActions from "@material-ui/core/DialogActions";
import CustomisedRadio from "./../CustomisedRadio.js";
import CustomTimeSelect from "./../CustomTimeSelect.js";
import { Formik, Field, ErrorMessage, Form } from "formik";
import CustomSelect from "./../CustomSelect";
import { Datepicker } from "react-formik-ui";
import { eventList, eventType } from "../../placeHolds/actions";
import { createEvent } from "../../createEvent/actions";
import { func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
//import { seatingCategory } from "shared/search/actions";
import { saveEvent, updateEventData, deleteEvent } from "shared/holdEvent/actions";
import { updateEvent } from "shared/updateEvent/actions";
import CustomizedButton from "../../../shared/components/customizedButton";
import SelectBudget from 'shared/components/SelectBudget';
import ModalBoxEvent from "../../../shared/components/ModalBoxEvent";
import { city } from "shared/search/actions";
import SeperateContactInfo from './seperateContactInfo';
import PlaceHoldErrorPopUp from './../placeHoldErrorPopUp';
// import { stat } from "fs";
import Autocomplete from "react-autocomplete";
import ContactPopup from './contactPopup';

let seatingType = [];
let seatingTypeContact = [];
let eventData = [];
let maxSeating = 0;
const RegEx = /^[0-9]{0,8}[-][0-9]{0,8}$/;

const styles = theme => ({
  root: {
    margin: 0,
    overflow: "visible"
  },
  paperWidthSm: {
    margin: "20px",
    overflow: "visible"
  },

  paper: {
    margin: "20px",
    overflow: "visible"
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      fontWeight: "500",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.46px",
      color: "black"
    }
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      borderRadius: "6px",
      // border: "solid 1px black"
    }
  }
});
function tConvert(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    time = time.slice(1);
    time.pop();
  }
  return time.join('');
}
// Using custom test method
function maxInRange(message) {
  return this.test("maxInRange", message, function (value) {
    const { path, createError } = this;
    let Varray = value && value.split('-');
    if (maxSeating != 0 && Array.isArray(Varray) && Varray[1] > maxSeating) {
      return createError({
        path,
        message: message
      });
    }
    return true;
  });
}
Yup.addMethod(Yup.mixed, "maxInRange", maxInRange);

const EventSchema = Yup.object().shape({
  eventName: Yup.string()
    .required("*Required"),
  city: Yup.string()
    .required("*Required"),
  event_type: Yup.string()
    .required("*Required"),
  budget: Yup.string()
    .required("*Required"),
  place_hold_seating: Yup.string()
    .matches(RegEx, "Please enter a range (i.e. 100-150)")
    .required("*Required")
    .maxInRange('Exceeds capacity for seating type'),
  startDateRange: Yup.string()
    .required("*Required"),
  // endDateRange: Yup.string()
  //   .required("*Required"),
  startTime: Yup.string()
    .required("*Required"),
  endTime: Yup.string()
    .required("*Required"),
  setUp: Yup.string()
    .required("*Required"),
  takeDown: Yup.string()
    .required("*Required"),
  seating: Yup.string()
    .required("*Required")
});

const DialogContent = withStyles(theme => ({
  root: {}
}))(MuiDialogContent);

const setUpArray = [
  {
    id: "1 hr",
    name: "1 hr"
  },
  {
    id: "1 hr",
    name: "2 hr"
  },
  {
    id: "1 hr",
    name: "3 hr"
  },
  {
    id: "1 hr",
    name: "4 hr"
  },
  {
    id: "1 hr",
    name: "5 hr"
  },
  {
    id: "1 hr",
    name: "6 hr"
  },
  {
    id: "1 hr",
    name: "7 hr"
  },
  {
    id: "1 hr",
    name: "8 hr"
  },
  {
    id: "1 hr",
    name: "9 hr"
  },
  {
    id: "1 hr",
    name: "10 hr"
  },
  {
    id: "1 hr",
    name: "11 hr"
  },
  {
    id: "1 hr",
    name: "12 hr"
  },
  {
    id: "1 hr",
    name: "24 hr"
  },
  {
    id: "1 hr",
    name: "48 hr"
  },
  {
    id: "1 hr",
    name: "72 hr"
  }
];

class SeperatePlaceHold extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
    saveLoader: false,
    loader: false,
    cityList: [],
    holdData: '',
    holdaid: '',
    date: "",
    calendar_enabled: this.props.calendar_enabled ? this.props.calendar_enabled : "blank",
    holdPopupState: false,
    id: "",
    open: false,
    showForm: false,
    eventsPresent: false,
    eventsSelected: false,
    eventsChecked: false,
    buttonDisabled: true,
    buttonSaveDisabled: true,
    getResponse: false,
    radioSelectedValue: "",
    seatingType: [],
    timeError: "",
    // setUpError: "",
    // takeDownError: "",
    hold_id: "",
    eventData: [],
    hold_rank: '',
    event_id: '',
    budget: '',
    city: '',
    // date:'',
    end_time: '',
    event_type: '',
    name: '',
    seating: '',
    seating_type: '',
    setup: '',
    teardown: '',
    start_time: '',
    update: false,
    hold_value: '',
    errorPopUp: false,
    eventType: [],
    minMaxSeatingHint: "",
    selectedDate: "",
    disableEndTimeAutoSelected: false,
    infoPopup:false,
    seatingTypeContact:[]
  };
  componentWillMount() {
    localStorage.removeItem('redirect_path')
    this.setState({ holdPopupState: false, eventsSelected: false });
    eventData = [];
    if (this.props.open === true) {
      this.setState({ open: true });
    }

    if (localStorage.getItem('fhtoken')) {
      this.props.eventList('past').then((response) => {

        this.setState({ getResponse: "calling", showForm: false });

        if (this.props.eventData.data.length > 0) {
          this.setState({ eventsPresent: true });

          response.eventDetails.data.map(function (data, idx) {
            // let holdRankArray = [];
            // let holdTypeArray = [];
            let obj = {};
            let min = data.min_seating ? data.min_seating : 0;
            let max = data.max_seating ? data.max_seating : 0;
            obj.planner_id = data.event_planner.id ? data.event_planner.id : null;
            obj.id = data.id ? data.id : null;
            obj.name = data.name ? data.name : null;
            obj.city = data.city ? data.city : null;
            obj.seating = min + '-' + max;
            obj.event_type = data.event_type ? data.event_type.id : null;
            obj.start_time = data.start_time ? data.start_time : null;
            obj.end_time = data.end_time ? data.end_time : null;
            obj.budget = data.budget ? data.budget : null;
            obj.seating_type = data.arrangement ? data.arrangement.id : null;
            obj.setup = data.setup_time ? data.setup_time : null;
            obj.teardown = data.teardown_time ? data.teardown_time : null;
            obj.start_date_follow = data.start_date;
            obj.end_date_follow = data.end_date;

            if (data.start_date !== null && data.end_date !== null) {
              if (data.start_date === data.end_date) {
                obj.date = moment(data.start_date).format("MM/DD/YYYY");
              } else {
                obj.date = moment(data.start_date).format("MM/DD/YYYY") + " to " + moment(data.end_date).format("MM/DD/YYYY");
              }
            }
            else {
              obj.date = null;
            }

            //hold values
            if (typeof (data.hold_count) == 'number') {

              if (data.hold_count === 1) {
                obj.holds = data.hold_count + " Hold";

              }
              else {
                obj.holds = data.hold_count + " Holds";

              }
            } else {
              obj.holds = "Booked";
            }
            obj.numeric_hold = data.hold_count;



            eventData.push(obj);
            return eventData;
          });
          this.setState({ eventData: eventData })
          this.props.followDataUpdate(eventData)
        }
      })
        .catch(error => this.setState({ getResponse: false, errorValue: true }));
      this.props.eventType().then(() => {
        this.setState({ showForm: false });
      })
        .catch(error => this.setState({ getResponse: false, errorValue: true }));

      /*this.props.seatingCategory().then(response => {
        seatingType = [];
        response.data.map(function (data, idx) {
          let obj = {};
          obj.value = data.id;
          obj.name = data.name;
          seatingType.push(obj);
          return seatingType;
        });
        this.setState({ seatingType: seatingType });
      })
        .catch(error => this.setState({ errorValue: true }));*/
    }

    this.props.city().then(response => {
    }).catch(error => { });

  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ eventsSelected: false, selectedDate: this.props.selectedDate })

    let cityList = []
    if (this.props.cityInfo !== nextProps.cityInfo && Object.keys(nextProps.cityInfo).length > 0) {
      nextProps.cityInfo.data.map((city, idx) => {
        cityList.push({ key: city.city, id: idx }); return cityList;
      })
      this.setState({ cityList: cityList })
    }

    this.setState({ holdPopupState: false });
    if (nextProps.open === true && (!localStorage.getItem('fhtoken') || parseInt(localStorage.getItem('type')) !== 0 || parseInt(localStorage.getItem("type")) === 1)) {
      this.props.openLoginDailog();
    } else if (nextProps.open === true) {
      this.setState({ open: true });
      this.setState({ infoPopup: true })
    }
    if (nextProps.calendar_enabled !== this.props.calendar_enabled) {
      this.setState({ calendar_enabled: nextProps.calendar_enabled });

    }

    if (this.props.placeHoldErrorPopUp !== nextProps.placeHoldErrorPopUp) {
      this.setState({ errorPopUp: nextProps.placeHoldErrorPopUp })
    }


    if (this.props.space && 'timeslots' in this.props.space && this.props.space.timeslots.length > 0 && Array.isArray(this.props.eventTypeData.data)) {
      let eventType = [];
      this.props.space.timeslots.map(res => {
        res.event_types.map(types => {
          let obj = this.props.eventTypeData.data.find(o => o.id === types.id);
          if (obj && 'id' in obj) {
            eventType.push(obj);
          }
        })
      })
      const unique = [...new Set(eventType.map(item => item))];
      this.setState({ eventType: unique });
    }

    if (this.props.space && 'arrangements' in this.props.space && this.props.space.arrangements.length > 0) {
      seatingType = [];
      seatingTypeContact =[];
      this.props.space.arrangements.map(function (data, idx) {
        let obj = {};
        obj.value = data.id;
        obj.name = data.name;
        obj.minimum = data.pivot.minimum;
        obj.maximum = data.pivot.maximum;
        seatingType.push(obj);
        let objContact = {};
        objContact.value = data.name;
        objContact.name = data.name;
        objContact.minimum = data.pivot.minimum;
        objContact.maximum = data.pivot.maximum;
        seatingTypeContact.push(objContact);
        return seatingType,seatingTypeContact;
      });
      this.setState({ seatingType: seatingType });
      this.setState({ seatingTypeContact: seatingTypeContact });
    }


  }

  handleLoginClick = () => {
    localStorage.setItem('widgetLogin', true);
    localStorage.setItem('widget_path', this.props.location.pathname)
    this.props.history.push('/login')
  }
  infoPopupOpen = () => {
   this.setState({infoPopup:!this.state.infoPopup})
  }
  handleSignUpClick = () => {
    localStorage.setItem('widgetLogin', true);
    localStorage.setItem('widget_path', this.props.location.pathname)
    this.props.history.push('/register')
  }

  getSeatingMinMax = (seatingId) => {
    if (this.props.space && 'arrangements' in this.props.space && this.props.space.arrangements.length > 0 && seatingId) {
      let obj = this.props.space.arrangements.find(o => o.id == seatingId);
      if (obj && 'id' in obj) {
        maxSeating = obj.pivot.maximum;
      }
    }
    return "";
  }

  handleClose = () => {
    this.setState({
      holdPopupState: false,
      saveLoader: false,
      loader: false,
      open: false,
      showForm: false,
      eventsSelected: false,
      eventsPresent: true,
      eventsChecked: false,
      buttonSaveDisabled: true,
      radioSelectedValue: '',
      budget: '',
      city: '',
      date: '',
      end_time: '',
      event_type: '',
      name: '',
      seating: '',
      seating_type: '',
      setup: '',
      teardown: '',
      start_time: '',
      event_id: '',
      start_date: '',
      end_date: '',
      hold_value: '',

    });

    if (this.props.updatePlaceholdClose) {
      this.props.updatePlaceholdClose()

    }

  };

  handleClickOpen = () => {
    if ((!localStorage.getItem('fhtoken') || parseInt(localStorage.getItem('type')) !== 0 || parseInt(localStorage.getItem("type")) === 1)) {
      this.props.openLoginDailog();
    } else {
      this.setState({ holdPopupState: false, open: true, galleryOpened: true, selectedDate: "" });
      this.setState({ infoPopup: true })
    }
  };

  handleCreateEventClick = (data) => {
    this.setState({ holdPopupState: false, hold_value: '', budget: '', city: '', date: '', end_time: '', event_type: '', name: '', seating: '', seating_type: '', setup: '', teardown: '', start_time: '', event_id: '', update: '', start_date: '', end_date: '', selectedDate: '' });
    maxSeating = 0;
    if (data.name) {

      let start_date;
      let end_date;
      let date;
      if (data.date !== null && data.date.includes('to') === true) {
        date = data.date.split('to');
        start_date = date[0];
        end_date = date[1];
      }
      else {
        start_date = data.date;
        end_date = data.date;

      }

      let setup = data.setup !== null && data.setup.split('.');
      let teardown = data.teardown !== null && data.teardown.split('.');
      this.setState({
        showForm: true, eventsChecked: false,

        budget: data.budget,
        city: data.city,
        date: '',
        end_time: tConvert(data.end_time),
        event_type: data.event_type,
        name: data.name,
        seating: data.seating,
        seating_type: data.seating_type,
        setup: setup[0] + " hr",
        teardown: teardown[0] + " hr",
        start_time: tConvert(data.start_time),
        event_id: data.id,
        update: true,
        start_date: start_date,
        end_date: end_date,
        hold_value: data.holds

      });
    }
    else {
      this.setState({ showForm: true, eventsChecked: false, disableEndTimeAutoSelected: false, start_date: this.state.selectedDate ? moment(this.state.selectedDate).format("MM/DD/YYYY") : '' })
    }
  };

  handleClickYes = () => {


    this.setState({
      name: '',
      city: '',
      event_type: '',
      budget: '',
      seating: '',
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      setup: '',
      teardown: '',
      seating_type: ''

    })
    this.setState({ saveLoader: false, showForm: true, eventsChecked: false, errorPopUp: false })

  }


  handleClickNo = (id) => {
    for (let i = 0; i < this.state.eventData.length; i++) {
      if (parseInt(id) === parseInt(this.state.eventData[i].id)) {

        let setup = this.state.eventData[i].setup.split('.');
        let teardown = this.state.eventData[i].teardown.split('.');

        this.setState({
          name: this.state.eventData[i].name,
          city: this.state.eventData[i].city,
          event_type: this.state.eventData[i].event_type,
          budget: this.state.eventData[i].budget,
          seating: this.state.eventData[i].seating,
          start_date: this.state.eventData[i].start_date_follow,
          end_date: this.state.eventData[i].end_date_follow,
          start_time: tConvert(this.state.eventData[i].start_time),
          end_time: tConvert(this.state.eventData[i].end_time),
          setup: setup[0] + " hr",
          teardown: teardown[0] + " hr",
          seating_type: this.state.eventData[i].seating_type,
          numeric_hold: this.state.eventData[i].numeric_hold,
          holds: this.state.eventData[i].holds,
          hold_value: this.state.eventData[i].holds,
          update: true,
          event_id: this.state.eventData[i].id


        })
        this.setState({ showForm: true })

        break;
      }
    }
    this.setState({ saveLoader: false, errorPopUp: false })
  }

  handleCancelClick = () => {
    this.setState(prevState => ({ holdPopupState: false, showForm: false, open: true }));
  };

  handleSubmit = (values, { setSubmitting }) => {
    this.setState({
      holdPopupState: false,
      name: values.eventName,
      // takeDownError: "",
      // setUpError: "",
      timeError: ""
    });

    let seating = values.place_hold_seating.split("-");
    let setup_time = values.setUp.split(" ");
    let takeDown = values.takeDown.split(" ");
    let json = {};
    json.event_type_id = values.event_type;
    json.name = values.eventName;
    json.city = values.city;
    json.start_date = moment(values.startDateRange).format("YYYY-MM-DD");
    if (values.endDateRange) {
      json.end_date = moment(values.endDateRange).format("YYYY-MM-DD");

    }
    else {
      json.end_date = moment(values.startDateRange).format("YYYY-MM-DD");
    }


    json.start_time = values.startTime + ":00";
    json.end_time = values.endTime + ":00";
    json.setup_time = setup_time[0];
    json.teardown_time = takeDown[0];
    json.min_seating = seating[0];
    json.max_seating = seating[1];
    json.budget = values.budget;
    json.arrangement_id = values.seating;


    var start_time = new Date(
      moment().format("LL") + " " + values.startTime + ":00"
    );
    var end_time = new Date(
      moment().format("LL") + " " + values.endTime + ":00"
    );

    // if (start_time.getTime() > end_time.getTime()) {
    //   this.setState({ timeError: "Invalid Time" });
    //   this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    // }
    let matched_min = new Date(moment().format("LL") + " 00:00:00");

    if (
      start_time.getTime() - setup_time[0] * 3600000 <
      matched_min.getTime()
    ) {
      // this.setState({ setUpError: "Invalid Time" });
      this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    }
    let matched_max = new Date(moment().format("LL") + " 24:00:00");
    if (end_time.getTime() + takeDown[0] * 3600000 > matched_max.getTime()) {
      // this.setState({ takeDownError: "Invalid Time" });
      this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    }

    if (
      // this.state.takeDownError == "" &&
      this.state.timeError === ""
      // this.state.setUpError == ""
    ) {
      this.setState(prevState => ({ showForm: false, eventsPresent: true }));

      if (this.state.update === true) {
        //update

        let arr = this.state.eventData;
        let update_arr = [];

        this.props.updateEvent(this.state.event_id, json).then((response) => {
          for (let i = 0; i < arr.length; i++) {
            let obj = {};
            if (parseInt(this.state.event_id) === parseInt(arr[i].id)) {
              obj.budget = values.budget;
              obj.city = values.city;
              obj.start_time = values.startTime + ":00";
              obj.end_time = values.endTime + ":00";
              obj.event_type = values.event_type;
              obj.id = this.state.event_id;
              obj.name = values.eventName;
              obj.seating = seating[0] + "-" + seating[1];
              obj.seating_type = values.seating;
              obj.holds = arr[i].holds;
              obj.setup = setup_time[0];
              obj.teardown = takeDown[0];
              obj.start_date_follow = values.startDateRange;
              if (values.endDateRange) {
                obj.end_date_follow = values.endDateRange;

              }
              else {
                obj.end_date_follow = values.startDateRange;

              }
              if (!values.endDateRange || values.startDateRange === values.endDateRange) {
                obj.date = moment(values.startDateRange).format("MM/DD/YYYY");
              } else {
                obj.date = moment(values.startDateRange).format("MM/DD/YYYY") + " to " + moment(values.endDateRange).format("MM/DD/YYYY");
              }
            }
            else {
              obj = arr[i];
            }

            update_arr.push(obj)
          }
          this.setState({ eventData: update_arr, update: false })
          this.props.followDataUpdate(update_arr)



        })
      }
      else {
        this.props.createEvent(json).then((response) => {

          let arr = this.state.eventData;
          let obj = {};
          obj.holds = '0 Holds';
          obj.budget = values.budget;
          obj.city = values.city;
          obj.start_time = values.startTime + ":00";
          obj.end_time = values.endTime + ":00";
          obj.event_type = values.event_type;
          obj.id = response.eventDetails.data.id;
          obj.name = values.eventName;
          obj.seating = seating[0] + "-" + seating[1];
          obj.seating_type = values.seating;
          obj.setup = setup_time[0];
          obj.teardown = takeDown[0];
          obj.start_date_follow = values.startDateRange;

          if (values.endDateRange) {
            obj.end_date_follow = values.endDateRange;

          }
          else {
            obj.end_date_follow = values.startDateRange;

          }
          if (!values.endDateRange || values.endDateRange === '' || values.startDateRange === values.endDateRange) {
            obj.date = moment(values.startDateRange).format("MM/DD/YYYY");
          } else {
            obj.date = moment(values.startDateRange).format("MM/DD/YYYY") + " to " + moment(values.endDateRange).format("MM/DD/YYYY");
          }
          arr.unshift(obj);

          this.props.followDataUpdate(arr, 'new added');
          this.setState({
            eventData: arr,
            radioSelectedValue: obj.id,
            buttonSaveDisabled: false,
            // takeDownError: "",
            // setUpError: "",
            timeError: ""
          });






        });
      }


    }
  };
  handleCheckboxClick = event => {
    this.setState({ eventsChecked: true, holdPopupState: false });
    this.setState({ buttonSaveDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });

    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName("option");

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName("input")[0].checked) {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text blue");
      } else {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text grey");
      }
    }
  };
  handleChangeEndDate = (values, setFieldValue) => {
    this.setState({ holdPopupState: false });
    setFieldValue('endDateRange', values.startDateRange ? moment(values.startDateRange).format("MM/DD/YYYY") : '01/01/2020')
  }
  holdPopup = () => {
    this.setState({ holdPopupState: true })

  }
  handleSaveToEventClick = () => {
    this.setState({ holdPopupState: false, errorPopUp: false })
    let widget = '';
    if (localStorage.getItem('widget')) {
      widget = localStorage.getItem('widget');
    }
    let params = {
      space_id: this.props.match.params.spaceId,
      event_id: this.state.radioSelectedValue,
      widget: widget
    };
    this.setState({ saveLoader: true })
    this.setState({ holdid: this.state.radioSelectedValue })
    let updatedEventArray = this.state.eventData;
    this.props.saveEvent(params, 'no').then((response) => {
      this.setState({ saveLoader: false })

      this.setState({ holdData: response.holeEventDetails.data })
      this.props.dateList(response.holeEventDetails.data);
      this.props.calendarData(response);
      //put below code above
      for (let i = 0; i < updatedEventArray.length; i++) {
        if (parseInt(this.state.radioSelectedValue) === parseInt(updatedEventArray[i].id)) {
          let value = updatedEventArray[i].numeric_hold;
          if (value === undefined) {
            value = 1;
            value = value + " Hold";

          }
          else {
            value = value + 1;
            value = value + " Holds";

          }
          updatedEventArray[i].holds = value;
          this.setState({ eventData: updatedEventArray })
          this.props.followDataUpdate(updatedEventArray)

        }
      }
      this.setState({ hold_id: response.holeEventDetails.data[0].id, hold_rank: response.holeEventDetails.data[0].hold_rank })
      this.setState(prevState => ({
        eventsPresent: false,
        eventsSelected: true
      }));
    })


  };

  handleRadioClick = event => {
    this.setState({ holdPopupState: false })
    this.setState({ buttonDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });

    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName("option");

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName("input")[0].checked) {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text blue");
      } else {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text grey");
      }
    }
  };
  handleOkClick = () => {
    let widget = '';
    if (localStorage.getItem('widget')) {
      widget = localStorage.getItem('widget');
    }
    this.setState({ holdPopupState: false, loader: true })
    let params = { hold_reason: this.state.radioSelectedValue, widget: widget };
    this.props.updateEventData(this.state.hold_id, params).then(() => {
      this.setState({ loader: false })

      if (this.props.updatePlaceholdClose) {
        this.props.updatePlaceholdClose()
      }
      this.setState({ open: false, eventsSelected: false, eventsPresent: true });

      this.props.releaseHold(this.state.hold_id, this.state.hold_rank)

    })
    let t = this;
    for (let i = 0; i < this.state.holdData.length; i++) {
      if (parseInt(this.state.holdid) === parseInt(this.state.holdData[i].event_id) && this.state.holdData[i].parent_id !== null) {

        if (t.props.localUpdate) {
          t.props.localUpdate(this.state.holdData[i])
        }
      }
    }
    if (this.props.closeWindow) {
      this.props.closeWindow();
    }
  };
  onSelectStart = (values, setFieldValue, eTime) => {
    if (eTime !== '') {
      let starttime = values.target.value;
      let time = starttime.split(':');

      if (parseInt(time[1]) + 30 === 60) {

        starttime = parseInt(time[0]) +
          1 +
          ":" +
          "00";

        let a = (parseInt(time[0]) + 1).toString();
        if (a.length === 1) {
          starttime = "0" + starttime;

        }

      }
      else {
        let time_for = parseInt(time[1]) + 30;
        if (time_for[1] === 0) {
          time_for = time_for.replace(0, '');
        }
        else {
          time_for = parseInt(time[1]) + 30;
        }

        starttime = time[0] + ":" + time_for;

      }
      if (this.state.disableEndTimeAutoSelected == false) {
        setFieldValue('endTime', starttime);
      }
    }
  }
  deleteEvent = (id, newElement) => {
    this.setState({ holdPopupState: false })

    this.props.deleteEvent(id).then(() => {
      let arr = this.state.eventData.filter(function (obj) {
        return obj.id !== id;
      });
      if (newElement) {
        this.setState({ eventData: arr, name: '', date: '' });

      }
      else {
        this.setState({ eventData: arr });

      }
      this.props.followDataUpdate(arr)

    })
  }
  onSelectedDate = (setFieldValue) => {
    setFieldValue('endDateRange', '')
  }

  stopLoader = () => {
    this.setState({
      loader: false,
      saveLoader: false
    })
  }
  render() {
    const { classes } = this.props;
    //const eventsPresent = this.state.eventsPresent;
    var currentDate = moment().toDate();
    let initialValues = {
      eventName: this.state.name ? this.state.name : "",
      city: this.state.city ? this.state.city : this.props.cityVal && this.props.cityVal.city,
      event_type: this.state.event_type ? this.state.event_type.toString() : "",
      budget: this.state.budget ? this.state.budget : "",
      place_hold_seating: this.state.seating ? this.state.seating : "",
      startDateRange: this.state.start_date ? this.state.start_date : "",
      endDateRange: this.state.end_date ? this.state.end_date : "",
      startTime: this.state.start_time ? this.state.start_time : "",
      endTime: this.state.end_time ? this.state.end_time : "",
      setUp: this.state.setup ? this.state.setup : "",
      takeDown: this.state.teardown ? this.state.teardown : "",
      seating: this.state.seating_type ? this.state.seating_type.toString() : "",
      check_select: ""
    };

    return (
      <>{/*this.state.infoPopup==true&&<div className="Contact-Venue-popup"
        style={{ lineHeight: '35px', fontWeight: 'normal', fontSize: '15px' }}>

        <DialogActions className="dialogActions">
          <span
            className="material-icons close closeButton"
            onClick={this.handleClose}
          >
            close
          </span>

        </DialogActions>
        <div style={{ textAlign: 'center', marginBottom: '25px' }}>
          <SeperateContactInfo
            follow={true}
            message={this.props.message}
            calendar_enabled={this.props.calendar_enabled}
            sendData={this.props.sendData}
            handleClose={this.handleClose}
            contactClick={this.props.contactClick} label="Contact"
            infoPopup={this.state.infoPopup} 
            infoPopupOpen={this.infoPopupOpen}/>
        </div>
    </div>*/}<div className="place-hold widget-place-hold">
            <CustomizedButton
            label={this.props.label}
            width="165px"
            height="36px"
            variant="outlined"
            onClickEvent={this.handleClickOpen}
            classes={{
              label: classes.label,
              root: classes.button
            }} />
            
          <Dialog
            className="success_modal_container place-hold-popup"
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
          >

            {parseInt(localStorage.getItem('type')) === 0

              ?
              <div>
                <ModalBoxEvent check={this.state.holdPopupState} />

                {parseInt(this.state.calendar_enabled) === 1 ?
                  <div>
                    <DialogActions className="dialogActions">
                      <span
                        className="material-icons close closeButton"
                        onClick={this.handleClose}
                      >
                        close
                      </span>
                    </DialogActions>

                    <DialogContent className="dialog-content add-new-event-popup">
                      <Grid container className="modalBox_content">
                        {localStorage.getItem("fhtoken") &&
                          parseInt(localStorage.getItem("type")) === 0 ? (
                          <Grid item xs={12} className="main_place_hold_section">
                            {this.state.showForm ? (
                              <Formik
                                initialValues={initialValues}
                                validationSchema={EventSchema}
                                onSubmit={this.handleSubmit}
                              >

                                {({ isSubmitting, values, handleBlur, handleChange, setFieldValue }) => (<Form>
                                  <div className="form">
                                    <div className="header">
                                      <div className="header-text">Add New Event</div>
                                      <div className="header-button-div-form button-left">
                                        <Button
                                          variant="outline-danger"
                                          className="white-button-small"
                                          onClick={this.handleCancelClick}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="outline-danger"
                                          className="red-button-small"
                                          type="submit"
                                        >
                                          {this.state.update === true ? 'Update' : 'Save'}
                                        </Button>
                                      </div>
                                    </div>
                                    <div className="form-content add-new-form-block">
                                      <div className="field-div big">
                                        <div className="input-label">Event Name</div>
                                        <div className="input-field-section">

                                          <Field
                                            className="input-field dark-black"
                                            type="text"
                                            name="eventName" />
                                          <div className="error">
                                            <ErrorMessage name="eventName" />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="field-div small city-section">
                                        <div className="input-label sm-pd-venue city-sm">City</div>
                                        <div className="input-field-section select-down-icon">
                                          {/* <input
      name="city"
      type="text"
      placeholder="San Francisco"
      className="input-field light-black"
      /> */}


                                          {/* <CustomSelect
                                     id="city"
                                     name="city"
                                     color="#62717a"
                                     width="100%"
                                     data={this.props.cityInfo.data}
                                     placeholder="Select"
                                     disable={this.state.hold_value?true:false}
                                   /> */}
                                          <Autocomplete

                                            fields={{ groupBy: 'value', value: 'menu' }}
                                            value={values.city}
                                            inputProps={{ id: 'city', placeholder: 'City' }}
                                            wrapperStyle={{ position: 'relative' }}
                                            items={this.state.cityList}
                                            getItemValue={item => item.key}
                                            shouldItemRender={(item, value) => item.key.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                            onChange={(e) => { setFieldValue('city', e.target.value); } }
                                            onSelect={(val, item) => { setFieldValue('city', val); } }
                                            renderMenu={children => (
                                              <div className="suggestionDiv">
                                                {children}
                                              </div>
                                            )}
                                            renderItem={(item, isHighlighted) => (
                                              <div
                                                className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                                key={item.id}>
                                                {item.key}
                                              </div>
                                            )} />
                                          <div
                                            className="error"
                                          >
                                            <ErrorMessage name="city" />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="field-div small float-right">
                                        <div className="input-label sm-pd-venue"># of People {values.seating && <span style={{ fontSize: '10px', color: '#1397e1' }}>{this.getSeatingMinMax(values.seating)}</span>}</div>
                                        <div className="input-field-section">
                                          {/* <CustomSelect
      id="place_hold_seating"
      name="place_hold_seating"
      color="#62717a"
      width="100%"
      selected="100-300"
      placeholder="Select"
      /> */}
                                          <Field
                                            className="input-field dark-black"
                                            type="text"
                                            name="place_hold_seating"
                                            placeholder="Ex-100-300"
                                            disabled={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />

                                          <div
                                            //  style={{ display: "inline-block" }}
                                            className="error"
                                          >
                                            <ErrorMessage name="place_hold_seating" />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="field-div small">
                                        <div className="input-label sm-pd-venue">Event Type</div>
                                        <div className="input-field-section select-down-icon">
                                          <CustomSelect
                                            id="event_type"
                                            name="event_type"
                                            value={values.event_type}
                                            color="#62717a"
                                            width="100%"
                                            data={this.state.eventType}
                                            placeholder="Select"
                                            disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />
                                          <div
                                            //  style={{ display: "inline-block" }}
                                            className="error"
                                          >
                                            <ErrorMessage name="event_type" />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="field-div small float-right">
                                        <div className="input-label sm-pd-venue">Venue Rental Budget</div>
                                        <div className="input-field-section">
                                          <div className="input-field-section select-down-icon">
                                            <SelectBudget />
                                            <div
                                              className="error"
                                            >
                                              <ErrorMessage name="budget" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="field-div small disp-none-big">
                                        <div className="input-label sm-pd-seating">Seating Type</div>
                                        <div className="input-field-section">
                                          <CustomSelect
                                            id="seating"
                                            name="seating"
                                            color="#62717a"
                                            width="100%"
                                            placeholder="Select"
                                            value={values.seating}
                                            seatingData={seatingType}
                                            disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />

                                          <div
                                            //  style={{ display: "inline-block" }}
                                            className="error"
                                          >
                                            <ErrorMessage name="seating" />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="field-div small">
                                        <div className="field-div smaller">
                                          <div className="input-label end-date-sm">
                                            Start Date Range
                                          </div>
                                          <div className="input-field-section">

                                            <Datepicker
                                              onSelect={() => this.onSelectedDate(setFieldValue)}
                                              onKeyDown={e => e.preventDefault()}
                                              minDate={currentDate}
                                              className="datepiker"
                                              name="startDateRange"
                                              dateFormat="MM/dd/yyyy"
                                              placeholder="Start Date"
                                              disabled={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false}
                                              readOnly={this.state.start_date != '' ? true : false} />

                                          </div>
                                        </div>

                                        <div className="field-div spacer disp-none-sm">to</div>
                                        <div className="field-div smaller float-right">
                                          <div className="input-label end-date-sm">
                                            End Date Range
                                          </div>
                                          <div className="input-field-section">

                                            <Datepicker
                                              onSelect={(e) => {
                                                e ? this.setState({ disableEndTimeAutoSelected: true }) : this.setState({ disableEndTimeAutoSelected: false });
                                              } }
                                              onKeyDown={e => e.preventDefault()}
                                              className="datepiker"
                                              minDate={moment(
                                                values.startDateRange
                                              ).toDate()}
                                              name="endDateRange"
                                              dateFormat="MM/dd/yyyy"
                                              placeholder="End Date"
                                              maxDate={moment(values.startDateRange)
                                                .add(15, "days")
                                                .toDate()}
                                              disabled={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />

                                          </div>
                                          <div className="input-label" style={{ fontSize: "11px" }}>Optional</div>
                                        </div>
                                      </div>

                                      <div className="field-div small float-right tall">
                                        <div className="field-div smaller">
                                          <div className="input-label sm-pd-start">Start Time</div>
                                          <div className="input-field-section">
                                            {/* <CustomTimeSelect
    names={time}
    name="startTime"
    value=""
    /> */}
                                            <CustomTimeSelect
                                              placeholder="Start Time"
                                              styleClass="browseVenue"
                                              name="startTime"
                                              value={values.startTime}
                                              handleChange={e => {
                                                handleChange(e);
                                                this.onSelectStart(e, setFieldValue);
                                              } }
                                              handleBlur={handleBlur}
                                              disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />
                                          </div>
                                        </div>

                                        <div className="field-div spacer disp-none-sm">to</div>

                                        <div className="field-div smaller float-right">
                                          <div className="input-label sm-pd-end">End Time</div>
                                          <div className="input-field-section">
                                            <CustomTimeSelect
                                              placeholder="End Time"
                                              styleClass="browseVenue"
                                              name="endTime"
                                              value={values.endTime}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />
                                            <div className="error">
                                              {this.state.timeError}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="field-div smaller">
                                          <div className="input-label sm-pd-req-up">Requested Set-up Time</div>
                                          <div className="input-field-section select-down-icon">
                                            <CustomSelect
                                              className="select-field lighter-black"
                                              id="setUp"
                                              setUpArray={setUpArray}
                                              name="setUp"
                                              value={values.setUp}
                                              placeholder="Select"
                                              color="#62717a"
                                              width="100%"
                                              disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />
                                            {/* {this.state.setUpError ? (
                                     <div
                                       className="error"
                                       style={{ display: "inline-block" }}
                                     >
                                       {this.state.setUpError}
                                     </div>
                                   ) : null} */}

                                            <div
                                              //  style={{ display: "inline-block" }}
                                              className="error"
                                            >
                                              <ErrorMessage name="setUp" />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="field-div smaller float-right">
                                          <div className="input-label sm-pd-req-down">Requested Tear Down Time</div>
                                          <div className="input-field-section select-down-icon">
                                            <CustomSelect
                                              className="select-field lighter-black"
                                              id="takeDown"
                                              setUpArray={setUpArray}
                                              name="takeDown"
                                              value={values.takeDown}
                                              placeholder="Select"
                                              color="#62717a"
                                              width="100%"
                                              disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />
                                            {/* {this.state.takeDownError ? (
                                     <div
                                       className="error"
                                       style={{ display: "inline-block" }}
                                     >
                                       {this.state.takeDownError}
                                     </div>
                                   ) : null}
     */}
                                            <div
                                              //  style={{ display: "inline-block" }}
                                              className="error"
                                            >
                                              <ErrorMessage name="takeDown" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="field-div small disp-none-small">
                                        <div className="input-label sm-pd-seating">Seating Type</div>
                                        <div className="input-field-section">
                                          <CustomSelect
                                            id="seating"
                                            name="seating"
                                            color="#62717a"
                                            width="100%"
                                            placeholder="Select"
                                            value={values.seating}
                                            seatingData={seatingType}
                                            disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false} />
                                          <div
                                            //  style={{ display: "inline-block" }}
                                            className="error"
                                          >
                                            <ErrorMessage name="seating" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                                )}
                              </Formik>

                            ) :
                              this.state.eventsPresent && this.state.calendar_enabled !== 0
                                ? (
                                  <div className="events">
                                    <div className="header">
                                      <div className="header-text">
                                        Confirmation
                                      </div>
                                      <div className="header-button-div button-left">
                                        <Button
                                          variant="outline-danger"
                                          class="white-button-big"
                                          onClick={this.handleCreateEventClick}
                                        >
                                          Create New Event
                                        </Button>
                                      </div>
                                    </div>
                                    <div className="events-content" style={{ overflow: "auto", margin: '0px' }} id="eventsContent">


                                      {this.state.eventData.map(data => {
                                        return (
                                          data.id !== 'dummyid' ?

                                            <div className="eventDiv placehold-event" style={{ margin: '20px auto 20px' }}>
                                              <div className="checkBoxDiv ">
                                                {/* <input
                                                       name="check_select"
                                                       value={data.id}
                                                       type="checkbox"
                                                       className="checkBox"
                                                       onClick={e =>
                                                         this.handleCheckboxClick(e)
                                                       }
                                                     /> */}
                                                <label className="radioTextLeft">

                                                  <CustomisedRadio event={true}
                                                    name="check_select"
                                                    value={data.id}
                                                    onChange={this.handleCheckboxClick}
                                                    // class="radioButton"
                                                    radioSelectedValue={parseInt(this.state.radioSelectedValue)} />
                                                  <span className="radioText">
                                                    <p className="large-text" style={parseInt(this.state.radioSelectedValue) === parseInt(data.id) ? { 'color': '#1397e1' } : { 'color': '#36434b' }}>{data.name.length > 15 ?
                                                      `${data.name.substring(0, 250)}...` : data.name}</p>
                                                    <p className="blue-text grey-date" style={parseInt(this.state.radioSelectedValue) === parseInt(data.id) ? { 'color': '#1397e1' } : { 'color': '#36434b' }}>{data.date}</p>
                                                  </span>
                                                </label>
                                                {/* <div className="detailsDivLeft">
                                                           <span className="material-icons edit-icon" onClick={()=>this.handleCreateEventClick(data)}>edit</span>
                                                       </div> */}
                                              </div>


                                              <div className="detailsDiv">

                                                <div className="actionDiv">
                                                  {/* <i className="hand-icon"></i> */}
                                                  {/*data.holds !== '0 Holds' && data.holds !== '0 Hold' ? (
                                                                  <p onClick={() => this.holdPopup()} className="grey-text">
                                                                    {data.holds}
                                                                </p>
                                                                ) : (
                                                                  <p onClick={()=>this.holdPopup()} className="grey-text">No Holds</p>
                                                                )*/}
                                                  {/* <div className="red_icon_div" style={data.holds ?null:{color:'red'}} > */}
                                                  <span className="material-icons edit-icon" style={data.holds !== '0 Holds' && data.holds !== '0 Hold' ? null : { color: 'red' }} onClick={() => this.handleCreateEventClick(data)}>edit</span>
                                                  <span style={data.holds !== '0 Holds' && data.holds !== '0 Hold' ? null : { color: 'red' }} onClick={data.holds !== '0 Holds' && data.holds !== '0 Hold' ? null : () => this.deleteEvent(data.id)} className="material-icons delete">delete</span>
                                                  {/* </div> */}
                                                </div>
                                              </div>
                                            </div>
                                            : null
                                        );
                                      })}

                                    </div>



                                    {/* {this.state.eventsChecked && ( )}*/}
                                    <div className="dialogFooter">
                                      <div className="buttonDiv" style={this.state.saveLoader === true ? { width: '96px' } : null}>
                                        {this.state.saveLoader === true ?

                                          <img style={{ height: '50px', marginTop: '0px', paddingTop: '0px' }} src={require('../../../shared/components/assets/images/loading.gif')} alt="Venue" />
                                          :
                                          <Button
                                            title={this.state.buttonSaveDisabled ? 'Select Event' : ''}
                                            variant="outline-danger"
                                            disabled={this.state.buttonSaveDisabled}
                                            onClick={this.handleSaveToEventClick}
                                            style={this.state.buttonSaveDisabled ? { 'background-image': 'linear-gradient(to bottom, grey, grey)', 'pointer-events': 'all' } : { 'background-image': 'linear-gradient(to bottom, #f1435e, #ee2738)' }}
                                            className="red-button"
                                          >


                                            Send Inquiry

                                          </Button>}
                                      </div>

                                    </div>
                                  </div>
                                ) : this.state.eventsSelected !== false ? (
                                  <div className="eventsSelected">
                                    <img
                                      className="image"
                                      src={require("./../assets/images/logo.png")}
                                      alt="FIRST-HOLD" />

                                    <p className="large-text">Inquiry sent</p>

                                    <p className="description">
                                      The venue manager will be notified and will be in
                                      touch shortly
                                    </p>

                                    {/*<p className="description">
                                        You’ll get notifications through your
                                        extension
                                        </p>*/}

                                    <p className="description blue-text">
                                      One last thing!
                                    </p>

                                    <p className="description">
                                      How ready are you to book?
                                    </p>

                                    <p className="grey-text">Select one</p>

                                    <div className="optionsDiv">
                                      <div className="option">
                                        <p className="option-text">
                                          Currently <br />
                                          Just Looking
                                        </p>

                                        <CustomisedRadio
                                          name="readyOption"
                                          value=" Currently Just Looking"
                                          onChange={this.handleRadioClick}
                                          class="radioButton"
                                          radioSelectedValue={this.state.radioSelectedValue} />
                                      </div>
                                      <div className="option">
                                        <p className="option-text">
                                          This is one of my top options!
                                        </p>

                                        <CustomisedRadio
                                          name="readyOption"
                                          value=" This is one of my top options!"
                                          onChange={this.handleRadioClick}
                                          class="radioButton"
                                          radioSelectedValue={this.state.radioSelectedValue} />
                                      </div>
                                      <div className="option">
                                        <p className="option-text">
                                          I’m probably going to book this space.
                                        </p>

                                        <CustomisedRadio
                                          name="readyOption"
                                          value=" I’m probably going to book this space."
                                          onChange={this.handleRadioClick}
                                          class="radioButton"
                                          radioSelectedValue={this.state.radioSelectedValue} />
                                      </div>
                                    </div>

                                    <div className="footerButtonDiv">
                                      {this.state.loader === true ?

                                        <img style={{ marginTop: '-14px', paddingTop: '0px' }} src={require('../../../shared/components/assets/images/loading.gif')} alt="Venue" />
                                        :
                                        <Button
                                          variant="contained"
                                          disabled={this.state.buttonDisabled}
                                          size={"large"}
                                          fullWidth={true}
                                          onClick={this.handleOkClick}
                                          className="grey-button"
                                        >
                                          ok
                                        </Button>}
                                    </div>
                                    <p class="powerby">Powered by FIRST-HOLD</p>
                                  </div>
                                ) : this.state.eventData.length === 0 && this.state.getResponse.toString() === "calling" ? (
                                  <div className="default">
                                    <div className="header">
                                      <div className="header-text">
                                        Select An Event For This Hold
                                      </div>
                                      <div className="header-button-div">
                                        <Button
                                          variant="outline-danger"
                                          class="white-button-big"
                                          onClick={this.handleCreateEventClick}
                                        >
                                          Create New Event
                                        </Button>
                                      </div>
                                    </div>
                                    <div className="uploader-content">
                                      <div className="uploader">
                                        <p className="red-text-content">
                                          Create Your First Event
                                        </p>
                                        <p className="black-text-content">
                                          Takes about a minute.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) :
                                  <div className="event-loading">
                                    <div className="header">
                                      <div className="header-text">
                                        Select An Event For This Hold
                                      </div>
                                      <div className="header-button-div button-left">
                                        <Button
                                          variant="outline-danger"
                                          class="white-button-big"
                                          onClick={this.handleCreateEventClick}
                                        >
                                          Create New Event
                                        </Button>
                                      </div>
                                    </div>
                                    <div class="load-box">
                                      Loading...
                                    </div>
                                  </div>}
                          </Grid>
                        ) : (
                          <Grid item xs={12} className="main_place_hold_section" style={{ textAlign: 'center' }}>
                            <DialogActions className="dialogActions">
                              <span
                                className="material-icons close closeButton"
                                onClick={this.handleClose}
                              >
                                close
                              </span>

                            </DialogActions>
                            <div className="form">
                              <div className="header">
                                <div className="header-text">Login</div>
                              </div>
                              <div className="form-content">
                                <center>Please Log-in as a Planner</center>
                                <div className="need_to_login_btn">
                                  <CustomizedButton onClickEvent={(e) => this.handleSignUpClick(e)} label="Sign Up" type="submit" />
                                  <CustomizedButton onClickEvent={(e) => this.handleLoginClick(e)} label="Sign In" type="submit" />
                                </div>
                              </div>
                            </div>
                          </Grid>
                        )}

                      </Grid>
                    </DialogContent>
                    {this.state.errorPopUp &&
                      <PlaceHoldErrorPopUp stopLoader={this.stopLoader} id={this.state.radioSelectedValue} error={this.props.errormessage} onClickYes={this.handleClickYes} onClickNo={this.handleClickNo} />}
                  </div>
                  :

                  parseInt(this.state.calendar_enabled) === 0 ?
                    <div className="Contact-Venue-popup"
                      style={{ lineHeight: '35px', fontWeight: 'normal', fontSize: '15px' }}>

                     {/* <DialogActions className="dialogActions">
                        <span
                          className="material-icons close closeButton"
                          onClick={this.handleClose}
                        >
                          close
                        </span>

                      </DialogActions>*/}

                      {/*<div className="default">
                        <div className="dont_single" style={{ marginTop: '37px' }}> Contact the Venue </div>
                        <Grid container className="contact-box" md={12} lg={12} xl={12}>
                          <div className="dont_p"> {this.props.venueName} cannot offer live holds, but they would still like to hear from you.</div>
                          <div className="dont_p">Let them know what you are planning.</div>
                        </Grid>
                    </div>
                      <div style={{ textAlign: 'center', marginBottom: '25px' }}>
                        <ContactPopup
                          follow={true}
                          message={this.props.message}
                          calendar_enabled={this.props.calendar_enabled}
                          sendData={this.props.sendData}
                          handleClose={this.handleClose}
                          contactClick={this.props.contactClick} label="Contact"
                          infoPopupOpen={this.infoPopupOpen} />
                      </div>*/}
        <div style={{ textAlign: 'center', marginBottom: '25px' }}>
          <SeperateContactInfo
            follow={true}
            message={this.props.message}
            calendar_enabled={this.props.calendar_enabled}
            sendData={this.props.sendData}
            handleClose={this.handleClose}
            contactClick={this.props.contactClick} label="Contact"
            infoPopup={this.state.infoPopup} 
            infoPopupOpen={this.infoPopupOpen}
            selectedDate={this.state.selectedDate}
            closeWindow={this.props.closeWindow}
            seatingData={seatingTypeContact}
            currentDate={currentDate}/>
        </div>
                    </div>
                    :
                    <div className="event-loading">
                      <div className="header">
                        <div className="header-text">
                          Select An Event For This Hold
                        </div>
                        <div className="header-button-div button-left">
                          <Button
                            variant="outline-danger"
                            class="white-button-big"
                            onClick={this.handleCreateEventClick}
                          >
                            Create New Event
                          </Button>
                        </div>
                      </div>
                      <div class="load-box">
                        Loading...
                      </div>
                    </div>}


              </div>
              :

              <Grid item xs={12} className="main_place_hold_section" style={{ textAlign: 'center' }}>
                <DialogActions className="dialogActions">
                  <span
                    className="material-icons close closeButton"
                    onClick={this.handleClose}
                  >
                    close
                  </span>

                </DialogActions>
                <div className="form">
                  <div className="header">
                    <div className="header-text">Don’t lose your date! 🎉</div>
                  </div>
                  <div className="form-content">
                    <center>To contact the venue, login or create an account (spoiler: it’s free!)</center>
                    <div className="need_to_login_btn">
                      <CustomizedButton onClickEvent={(e) => this.handleSignUpClick(e)} label="Sign Up" type="submit" />
                      <CustomizedButton onClickEvent={(e) => this.handleLoginClick(e)} label="Login" type="submit" />
                    </div>
                  </div>
                </div>
              </Grid>}

          </Dialog>
        </div></>
    );
  }
}

SeperatePlaceHold.propTypes = {
  classes: PropTypes.object.isRequired,
  eventList: func.isRequired,
  eventType: func.isRequired,
  //seatingCategory: func.isRequired,
  createEvent: func.isRequired,
  saveEvent: func.isRequired,
  updateEvent: func.isRequired,
  updateEventData: func.isRequired,
  deleteEvent: func.isRequired,
  city: func.isRequired


};

const mapStateToProps = state => {
  return {
    eventData: state.data.eventList.eventDetails,
    eventTypeData: state.data.eventList.eventTypeDetails,
    //seatingCategoryData: state.data.browseVenueData.arrangementData,
    holdEventData: state.data.saveEvent,
    cityInfo: state.data.browseVenueData.cityData,
    placeHoldErrorPopUp: state.data.saveEvent.renderErrorPopUp,
    errormessage: state.data.saveEvent.message

  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        eventList,
        eventType,
        //seatingCategory,
        createEvent,
        saveEvent,
        updateEvent,
        updateEventData,
        deleteEvent,
        city
      },
      dispatch
    )
  )
);
export default enhance(SeperatePlaceHold);
