import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Typography from '@material-ui/core/Typography';

import CustomizedButton from '../shared/components/customizedButton';
import { verifyConfirmation, getUser } from 'data/person/actions';
import { Link } from 'react-router-dom';
import { loginWithMobile, bootstrap, loginOtpVerification, resendOTP, loginWithEmail, signUpEmailOtpVerification } from 'shared/auth/actions';

import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import OtpInput from 'react-otp-input';

import './assets/styles/confirm.scss';

const ConfirmSchema = Yup.object().shape({
  confirmationCode: Yup.string().required('*Confirmation code is required')
  // confirmationCode: Yup.number().required('*Confirmation code is required').typeError("*Not a number").test('len', 'Confirmation code must be of 6 digit', val => val && val.toString().length === 6)
  ,

});

class ConfirmationPhone extends Component {

  UNSAFE_componentWillMount() {
    this.props.getUser();
  }
  state = {
    otp: '',
    step: 1,
    phone_number: this.props.phone_number
  }
  handleSubmit = (otp) => {
    this.setState({ otp: otp })
    const formData = { contact_type: 1, confirmation_code: otp }
    if (otp && otp.length == 6) {
      this.props.verifyConfirmation(formData)
        .then((response) => {
          const token = localStorage.getItem('temptoken');
          const id = localStorage.getItem('tempid');
          localStorage.setItem('fhtoken', token);
          localStorage.setItem('id', id);
          localStorage.removeItem('tempid');
          localStorage.removeItem('temptoken');
          // this.props.history.push('/browseVenue?signup=1')
          //window.location.href = '/browseVenue?signup=1'
          const widget = localStorage.getItem('widget_path')
          if (localStorage.getItem('widgetLogin') === 'true') {
            localStorage.removeItem('widgetLogin');
            localStorage.removeItem('widget_path');

            this.props.history.push(widget);
          }
          else if (localStorage.getItem('redirect_path')) {
            let redirectPath = localStorage.getItem('redirect_path');
            localStorage.removeItem('redirect_path');
            this.props.history.push(redirectPath)

          }

          else if (this.props.type === 0) {
            window.location.href = '/browseVenue?signup=1'
          }

          else {
            this.props.history.push('/myVenue')
          }
        })
        .catch(error => { this.setState({ otp: '' }) })

    }
  };
  onResendOTP = (type) => {

    const params = { mobile_no: this.state.phone_number, contact_type: type };

    this.props.resendOTP(params).then((response) => {
      this.setState({ step: this.state.step - 1, otp: '' });
    });
  }
  onHelp = () => {
    this.setState({ step: 2 });
  }

  render() {
    return (
      <div>
        <div className="confirm mobile-otp">
          <Grid className="signup__inner" container justify="center">

            {this.state.step === 1 && (

              <Grid item xs={6} sm={6} md={4} lg={4} className="min-wid-email confirmationBox">
                <h3 className="confirmationPadding" align="center">&nbsp;</h3>
                <Grid item className="logo">
                  <Link to='/'>
                    <img src={require('shared/components/assets/images/LogoWithText.png')} alt="logo" />
                  </Link>
                </Grid>
                <div className="confirm-email">
                  <h2 className="texSentTo">Check your mobile phone!</h2>

                  <div className="yourBusiness" align="center">
                    <h1 className="phoneNumber">We sent a 6-digit confirmation code to {this.props.phone_number}</h1>
                    <h1>It expires shortly, so enter it soon.</h1>
                  </div>
                  <Grid>
                    <Grid container justify="center">
                      <Grid item md={12} lg={12} xl={12}>
                        <OtpInput
                          value={this.state.otp}
                          onChange={(e) => this.handleSubmit(e)}
                          numInputs={6}
                          isDisabled={this.state.otp && this.state.otp.length == 6
                            ? true
                            : false}
                          containerStyle="otp-input-box"
                          inputStyle="otp-input" />
                      </Grid>
                      <Typography gutterBottom className="bottom-link">
                        <Link onClick={() => this.onHelp()} class="blue-text">Need Help?</Link>
                      </Typography>
                    </Grid>
                    {/* <Grid container justify="center">
                        <h3 className="emailLastText"></h3>
                      </Grid> */}
                  </Grid>
                </div>
              </Grid>)}


            {this.state.step === 2 && <Grid container md={12} lg={12} xl={12} className="help-container" justify="center">
              <Grid class=" need-help-block">
                <div className="need-help">
                  <Grid item className="logo">
                    <Link to='/'>
                      <img src={require('shared/components/assets/images/LogoWithText.png')} alt="logo" />
                    </Link>
                  </Grid>
                  <div class="dark-text">
                    <Typography gutterBottom className="heading-2">
                      How can we help?
                    </Typography>
                  </div>
                  <div >
                    <Typography gutterBottom className="bottom-link">
                      <Link class="blue-text" onClick={() => this.onResendOTP('1')}>Resend Code</Link>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
            }

          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    phone_number: state.data.auth.phone_number,
    sending: state.data.auth.sending,
    type: state.data.auth.type
  };
};
export default
  connect(
    mapStateToProps,
    dispatch => bindActionCreators({
      verifyConfirmation,
      getUser,
      resendOTP
    }, dispatch),
  )(ConfirmationPhone);
